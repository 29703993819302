<template>
	<div class="watchers-list">
		<template v-if="participants">
			<div class="user" v-for="(player, idx) in participants" :key="'watch'+player.id" :title="player.email">
				<div class="user-image">
				<gameplayer :playerId="player.id" :player="player">
					<template v-slot:video>
						<div class="playerVideo" :title="player.email">
							<template v-if="gameState.videoEnabled">
								<videocontrol :videoId="getPlayerVideoId(player.id)">
								</videocontrol>
							</template>
							<template v-else>
								no video... {{gameState}}
							</template>
						</div>
					</template>
				</gameplayer>
				<div class="name">{{getPlayerName(player)}}</div>
				<div class="dropdownMenu">
					<b-dropdown>
					<template #button-content> &#xFE19;</template>
						<b-dropdown-item @click="addToTable(player)">Add to Table</b-dropdown-item>
					</b-dropdown>
				</div>
				</div>
			</div>
		</template>
	</div>
</template>
<script>
	import { mapState } from 'vuex';
	import gameplayer from "@/components/game/player";
	import profilepicture from '@/components/profilepicture';
	import gametransitions from "@/components/game/gametransitions";
	import videocontrol from "@/components/twvideo/video";
	import avcontrols from "@/components/twvideo/avcontrols";

	import { ParticipantStates} from '@/grpcservices/Game_pb';

	export default {
		name: "watchers",

		mixins: [
			gametransitions
		],

		components: {
			profilepicture,
			gameplayer,
			videocontrol,
			avcontrols,
		},

		props: {
			participants: {
				Type: Object,
				Required: true,
			},
			gameid: {
				Type: String,
				Required: true,
			},
		},

		data() {
			return {
				isprocessing: false,
				error: null,
				meid: null,
				ParticipantStates: ParticipantStates,
			}
		},

		mounted() {
			this.meid = this.userinfo.id;
		},

		computed: {
			...mapState(
				["userinfo", "gameState", "gameDef"]
			),
			getPlayerLabel(participant){
				if(participant.gameotp?.length > 1)
					return participant.userDisplayName+"("+participant.gameotp+")";

				return participant.userDisplayName
			},	
		},

		methods: {
			getPlayerVideoId(userid) {
				return `video-${userid}`;
			},

			addToTable(player) {
				this.$parent.updatePlayerState(player, ParticipantStates.PLAYING)
			}
		},
	};
</script>
