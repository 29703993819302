<template>
    <b-modal ref="scoreboard-modal" hide-footer class="modal-md">
        <template #modal-header="{ close }">
            <template v-if="gameState.jokerTray">
                <zcard v-for="(card, cardIndex) in gameState.jokerTray.cards" 
                    :key="cardIndex" :suit="card.suit" :rank="card.rank" :isFaceDown="false" style="height:50%">
                </zcard>
            </template>

            <template v-if="gameState.bustTray">
                <zcard v-for="(card, cardIndex) in gameState.bustTray.cards" 
                    :key="cardIndex" :suit="card.suit" :rank="card.rank" :isFaceDown="false">
                </zcard>
            </template>
            <h5 v-if="gameState.gameDef.hasPot" class="m-2">{{'Total Pot - ' + winningHandScore}}</h5>
            <button size="sm" class="close" @click="close">×</button>
        </template>
        <template v-if="gameState && gameState.tableState == TableStates.DECLARE">
            <div v-for="(player, playerId) in players" :key="playerId" style="display:flex;height:75px;">
                    <div style="position:relative">
                        <span class="winner" v-if="player.hand.state  == ParticipantHandStates.WON">
                            <fa-icon icon="trophy" style="color:#ffc107;"/> {{getPlayerName(player)}} won {{player.hand.amountWon - player.hand.gameBet}} <br/>
                        </span>
                        <span v-else>
                            {{getPlayerName(player)}} lost {{player.hand.gameBet}} <br/>
                        </span>
                    </div>
                     <div style="position:relative;display:flex;">
                        <template v-if="player.hand.groups">
                            <span v-for="groupObj in player.hand.groups"
                                :key="`my-group-main-` + groupObj.group" style="display:flex;">
                                    <zcard v-for="(card, cardIndex) in groupObj.cards" 
                                        :key="`my-list-` + groupObj.group + cardIndex"
                                        :suit="card.suit"
                                        :rank="card.rank" 
                                        :isFaceDown="false">
                                    </zcard>
                            </span>
                        </template>
                    </div>
            </div>
        </template>
      
        <div>
            <b-btn class="gold-button mt-2" @click="startRound(gameDef.id)" 
                    style="float:right" v-if="isDealer || isHost || (winner && winner.id == loggedInPlayer.id)">PLAY NEXT ROUND
            </b-btn>
        </div>
    </b-modal>
</template>
<script>
	import DEBUG from '@/common/DEBUG'
    import gametransitions from "@/components/game/gametransitions";
    import { TrayTypes, TableStates, ParticipantHandStates } from '@/grpcservices/Game_pb';
    import playingcard from "@/components/game/playingcard";
    import zcard from "@/components/game/zcard";
    import { mapState, mapActions } from 'vuex';

	export default {
		name: "scoreboard",

        mixins: [
			gametransitions
		],

        components: {
			playingcard,
            zcard
		},

		props: {
            isDealer: Boolean,
            winner: Object,
            canStartGame:Boolean,
            isHost:Boolean
		},

		computed: {
            ...mapState(["userinfo", "gameState","gameDef"]),

            loggedInPlayer() {
                return _.find(this.gameState.participants, {id: this.userinfo.id});
            },
            
            winningHandScore() {
                return this.gameState.pot.netAmount;
            }
			
		},

		data() {
			return {
                ParticipantHandStates: ParticipantHandStates,
                TableStates: TableStates,
                TrayTypes:TrayTypes,
			}
		},

		mounted() {
			
		},

        methods: {
            ...mapActions(["closedAction_DeclareScore", "closedAction_DeclareWinningAmount", "updateParticipantHandState"]),

            show() {
				const that = this
				that.error = null
				that.$refs["scoreboard-modal"].show();
			},

            hide() {
				const that = this
				that.error = null
				that.$refs["scoreboard-modal"].hide();
			},

            async doDeclareScore(player) {
				const that = this
				if(!player.hand.roundBet) {
					return false
				}

				that.error = null;
				try {
					const invokeArgs = {
						gameId: that.gameState.id,
						forPlayerId: player.id,
						score: player.hand.roundBet,
					};
					DEBUG.log('doDeclareScore...', invokeArgs)
					await that.closedAction_DeclareScore(invokeArgs);
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred - ${error.message}`)
				}
			},

            startRound(gameId) {
                this.hide();
                this.$parent.startRound(gameId)
            },
            
            async doDeclareAmountWon(player) {
				const that = this
				if(!player.hand.amountWon) {
					return false
				}

				that.error = null;
				try {
					const invokeArgs = {
						gameId: that.gameState.id,
						forPlayerId: player.id,
						amountWon: player.hand.amountWon,
					};
					DEBUG.log('doDeclareAmountWon..', invokeArgs)
					await that.closedAction_DeclareWinningAmount(invokeArgs);
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred - ${error.message}`)
				}
			},

            async updatePlayerWinner(participant) {
				const that = this
				DEBUG.log('doUpdateParticipantHandState...', participant)

				that.error = null;
				try {
					const invokeArgs = {
						gameId: that.gameState.id,
						playerId: participant.id,
						participantHandState: ParticipantHandStates.WON,
					};
					await that.updateParticipantHandState(invokeArgs);
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred - ${error.message}`)
				}
			},


        }
	};
</script>
<style scoped>
.saveBtn {
    width: 30px;
    height: 38px;
    vertical-align: middle;
    margin: 2px;
    color: #d8a824
}
</style>
