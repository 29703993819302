<template>
	<span>
		<span v-if="gameState.tableState < TableStates.PLAY" class="input-group mb-3" style="margin:10px">
			<input type="text" class="form-control" ref="potAmount" 
					style="width:50px;padding:2px;" :value="minBet">
			<span class="input-group-append input-group-text" 
					@click="() => addToPot($refs.potAmount.value)">Set Table Bet</span>
		</span>
		<span v-else>
			<b-btn class="gold-button" @click="() => addToPot(minBet)" >{{isBlind ? 'Blind '+minBet : 'Chaal '+minBet}}</b-btn>
			<b-btn :disabled="!canRaise" class="gold-button" @click="() => addToPot(minBet*2)">{{isBlind ? 'Blind '+minBet*2 : 'Chaal '+minBet*2}}</b-btn>
		</span>
	</span>
</template>

<script>
	import DEBUG from '@/common/DEBUG'
	import gametransitions from "@/components/game/gametransitions";
    import { mapState, mapActions } from 'vuex';
	import {
		TableStates
	} from '@/grpcservices/Game_pb';

	export default {
		name: "pot",

		mixins: [
			gametransitions
		],

		props: {
			isBlind:Boolean,
			walletBalance: Number,
		},

		computed: {
            ...mapState(["gameState"]),

			chaal(){
				return this.gameState.pot.potMinBet;
			},

			canRaise(){
				return this.chaal*2>this.gameState.pot.potMaxBet;
			},

			minBet(){  // in case of table bet allow to override...but limit based on def.
				return this.gameState.tableState < this.TableStates.PLAY ? 
							this.$refs.potAmount?.value || this.chaal :
							this.chaal*(this.isBlind ? .5 :1);
			},
		},

		data() {
			return {
				TableStates: TableStates,
			}
		},

		mounted() {
		},

		methods: {
            ...mapActions(["openAction_AddToPot"]),

            async addToPot(amountToAdd, cb) {
				const that = this;

				var currentWalletBalance = this.walletBalance
				var betAmount = amountToAdd || 
								this.$refs.potAmount?.value || 
								this.minBet;
			
				if(currentWalletBalance && currentWalletBalance >= betAmount) {
					console.log("Pot::addToPot "+betAmount+" from wallet balance "+currentWalletBalance+" playing blind:"+this.isBlind);
				
					try {
							const invokeArgs = {
								gameId: that.gameState.id,
								playerId: that.meid,
								amount: parseInt(betAmount)
							};
							await that.openAction_AddToPot(invokeArgs);
						//	this.$parent.showTableMessage(' Chaal : ' + betAmount);
							cb ? cb() : {};
						}
						catch (error) {
							that.notifyError(`an error occurred while adding to pot - `+ error)
						}
				} else {
					this.$parent.showTableMessage('Insufficient Wallet, Add to Wallet')
					this.$parent.showWalletModel()
				}
			},
		}
	};
</script>
<style scoped>
</style>
