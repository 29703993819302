<template>
    <b-modal size="sm" ref="gameSwitcher"  hide-footer class="modal-md">
        <template>
            <h3> Playing {{gameState.gameDef.name}} <hr/></h3>
        </template>
        <template class="modal-body">
            <h4>Pick a different game to play</h4>
            <div>
                <select v-model="selected">
                    <template  v-for="gd in allGameDefs" >
                        <option v-if="gd.name != gameState.gameDef.name" style="display:flex;height:75px;" :value="gd.id" :key="gd.id" @click="selected = gd.id"> 
                            {{gd.name}}
                        </option>
                    </template>
                </select>
                <hr/>
            </div>
        </template>
        <div>     
                <button type="button" v-b-modal.modal-sm class="btn btn-secondary" size="sm"  @click="hide()">Cancel</button>
                <button type="button" v-b-modal.modal-sm class="btn btn-primary" size="sm" @click="changeGame(selected)">Change Game</button>
        </div>
    </b-modal>
</template>

<script>
    import { mapState, mapActions } from 'vuex';

	export default {
		name: "gameSwitcher",
		props: {
            
		},
        data(){
           return {
               selected:''
           };
        },
		computed: {
            ...mapState(["gameState","allGameDefs"]),   
		},
        async mounted(){
            this.selected = this.gameState.gameDef.id;
            await this.getGameDefinitions({ friendly: true });
        },
        methods: {
            ...mapActions(["getGameDefinitions"]),
            changeGame(id){
                console.log("Changing game to ",this.selected);
                this.hide();
                this.$parent.changeGame(id);
            },
            show() {
				this.$refs.gameSwitcher.show();
			},
            hide() {
				this.$refs.gameSwitcher.hide();
			},
        }
	};
</script>
<style scoped>
</style>
