<template>
	<div :style="getCommunityTrayStyle()" :id = "'CommunityTray'+trayName">
		<basetray :tray="tray" :displayType="'spread'" :isFaceDown="false"
			:draggable="false" :droppable="true" :onAddItem="onAddItem"
			:customComponentData="getComponentData()"
			:trayHint="trayHint"
			:trayName="trayName"
			>
		</basetray>
	</div>
</template>
<script>
	import basetray from "@/components/game/basetray";
	import trayutils from "@/components/game/trayutils";
	import { TrayTypes, TableStates} from '@/grpcservices/Game_pb';
	import DEBUG from '@/common/DEBUG'
	import { mapState, mapActions } from 'vuex';

	export default {
		name: "communitytray",

		components: {
			basetray
		},

		mixins: [
			trayutils
		],

		props: {
			tray: {
				Type: Object
			},
			trayName: {
				Type: Number
			},
			trayHint: {
				type: String,
			},		
		},
		data() {
			return {
				TableStates: TableStates
			}
		},

		computed: {
		},

		methods: {
			onAddItem: function(selectedCard) {
				if (this.getGlobalVar('$sourceTray') == this.trayName) {
					return false
				}

				console.log('Dropped a community card', selectedCard, "from tray ",this.getGlobalVar('$sourceTray'))

				if (this.gameState.tableState == TableStates.DEAL && this.getGlobalVar('$sourceTray') == TrayTypes.DEAL_TRAY) {
					this.addCommunityCard(selectedCard);
				}
			return false
			},
			async addCommunityCard(selectedCard)
			{
			const that = this
				try {
						that.meplayer.sourceTrayType = this.getGlobalVar('$sourceTray')
						that.meplayer.targetTrayType = this.trayName
						that.meplayer.sourceCardIndex = selectedCard

						await that.doMoveAction(that.gameState, that.meplayer)
						//move errors to provide/inject
						that.error = null
					} catch (error) {
						this.pickCardInProgress = false
						that.error = `unable to pick community card - ${error.message}`
						that.notifyError(error.message)
					}	
			},
			getCommunityTrayStyle ()			
			{   
				if (this.trayName == TrayTypes.COMMUNITY_TRAY2)
					return "position:relative;top:0px;left:0px;margin:0px;width:100%;transform:translateY(-20px);z-index:2";
				else if (this.trayName == TrayTypes.COMMUNITY_TRAY3)
					return "position:relative;top:0px;left:0px;margin:0px;width:100%;transform:translateY(-40px);z-index:3";
				
				return "position:relative;top:0px;left:0px;margin:0px;width:100%;"
				//return "position:relative;min-height:100px;top:0px;left:0px;margin:0px;min-width:66px;width:100%;"
			},
			getComponentData() {
				return {
					trayName: TrayTypes.COMMUNITY_TRAY
				}
			},
			checkMove() {
				return false
			},
		}
	};
</script>