<template>
    <div class="">
        <template v-if="tableState == TableStates.PLAY">
            <template v-if="isRummy && hasPlayerDropped(player)">
                <div class="table-message">
                    <p>You have dropped out, you lost {{player.hand.gameBet}}.</p>
                </div>
                <!-- <scorebox :player="player"/> -->
            </template>
            <div v-else-if="!isRummy"> 
                <div :class="tableMessageClass">
                   <p v-for="(msg) in gameState.messages" :key=msg style="margin-bottom:0rem">                 
                       {{ReplaceEmailByName(msg)}}
                   </p>
                </div>
            </div>
        </template>
        <template v-else-if="tableState == TableStates.SHOW">
            <div class="table-message">
                <template v-if="isRummy">
                    <p v-if="winner.id!=player.id">{{getPlayerName(winner)}} has made a show.</p>
                    <p v-else-if="isActive"> "OOPS!!!!! Your winning hand is questioned! </p>
                    <p v-else> Congratulations {{getPlayerName(winner)}} !!!</p>
                </template>
                <div v-else :class="tableMessageClass"> 
                    <p v-for="(msg) in gameState.messages" :key=msg style="margin-bottom:0rem">
                        {{ReplaceEmailByName(msg)}}
                    </p>
                </div>
                <p>
                    {{showPrompt}}
                </p>
            </div>
        </template>
        <template v-else-if="tableState == TableStates.DECLARE || tableState == TableStates.ROUNDCOMPLETE">
            <div class="table-message">
                <template v-if="winner.id != player.id">
                    <div v-if="gameState.gameDef.gameType != GameType.RUMMY" :class="tableMessageClass">
                        <p v-for="(msg) in gameState.messages" :key=msg style="margin-bottom:0rem">                 
                            {{ReplaceEmailByName(msg)}}
                        </p>
                    <div v-if="isRummy && 
                                player.hand.state == ParticipantHandStates.INPLAY">
                        <p>You may review your hand and declare, current score is {{player.hand.systemCalculatedScore}}.<br></p>
                        <!-- <scorebox :player="player" :score="player.hand.systemCalculatedScore"/> -->
                    </div>
                </template>
                <template class="table-message" v-else>
                    <p v-if="isRummy"> 
                        <fa-icon :icon="['far', 'fa-kiss-wink-heart']" class="icon" />
                        You won! Wait for others to declare before starting next round.
                    </p>
                    <div v-if="!isRummy" :class="tableMessageClass">
                        <p v-for="(msg) in gameState.messages" :key=msg style="margin-bottom:0rem">                 
                            {{ReplaceEmailByName(msg)}}
                        </p>
                    </div>
                </template>
                <p v-if="!isDealerOrHost && player.hand.state ==  ParticipantHandStates.DECLARED">
                    Wait for next round to begin.
                </p>
            </div>
        </template>
        <template v-else-if="tableState == TableStates.DEAL">
            <div class="table-message">
                <div v-if="!isRummy" :class="tableMessageClass"> 
                    <p v-for="(msg) in gameState.messages" :key=msg style="margin-bottom:0rem">
                        {{ReplaceEmailByName(msg)}}
                    </p>
                </div>
            </div>
        </template>
	</div>
</template>
<script>
	import DEBUG from '@/common/DEBUG'
	import gametransitions from "@/components/game/gametransitions";
    import scorebox from "@/components/game/scorebox";
    import { mapState, mapActions } from 'vuex';
    import {
		TrayTypes,
		ParticipantStates,
		ParticipantHandStates,
		GameStates, TableStates,
        GameType
	} from '@/grpcservices/Game_pb';

	export default {
		name: "tableactions",

		mixins: [
			gametransitions
		],

        components: {
            scorebox
        },

		props: {
			playerId: String,
            tableState: Number,
            isActive: Boolean,
            player: Object,
            winner: Object,
            isDealerOrHost : Boolean,
		},

		computed: {
            ...mapState(["userinfo", "gameState"]),
			
            loggedInPlayer() {
                return _.find(this.gameState.participants, {id: this.userinfo.id});
            },

            isRummy () {
                return this.gameState.gameDef.gameType == GameType.RUMMY;
            },

            pokerWinningHand () {
                if (this.gameState.gameDef.gameType != GameType.POKER)
                    return "";
                return " with " + this.gameState.showReason;
            },

            showPrompt () {
                var isWinner = (this.winner.id == this.player.id);
                var ret = '';
                if(isWinner) {        
                    // challenge rejection flow..
                    if(this.isActive) 
                        ret = "Your winning hand has been rejected, do acknowledge or challenge.";
                    else
                        ret = "Waiting for "+this.getActivePlayerName()+" to confirm";
                } else if(this.isActive){ // if reviewer/approver
                        ret = "Do you accept?";                        
                } else // all others
                    ret =  "Waiting for "+this.getActivePlayerName()+" to confirm the show.";
            return ret;
            },

            tableMessageClass() {
                DEBUG.log ("in table message class, msg len=" + this.gameState.messages.length);
                return (this.gameState.messages.length >= 4) ? "table-message4" 
                                                           : "table-message" + this.gameState.messages.length;
            },
		},

		data() {
			return {
                playerName: this.getPlayerName(this.player),
                TableStates: TableStates,
                GameType: GameType,
                ParticipantHandStates: ParticipantHandStates,
                hasWon: null,
                askForScore: false,
                score: null
			}
		},

		mounted() {
			DEBUG.log('PLAYER...', this.player, this.isActive, this.tableState)
		},

		methods: {
            ...mapActions(["closedAction_DeclareShow", "closedAction_DeclareChallenge"]),

            async acceptShow() {
				const that = this
				let participant =  this.winner
				DEBUG.log('doDeclareChallenge...accept', participant)

				that.error = null;
				try {
					const invokeArgs = {
						gameId: that.gameState.id,
                        gameDefId: that.gameState.gameDef.id,
						playerId: participant.id,
                        score: 0,
						hasWon: true,
						closeGame: false,
					};
					await that.closedAction_DeclareChallenge(invokeArgs);
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred - ${error.message}`)
				}
			},

			async rejectShow() {
				const that = this
				let participant =  this.winner
				DEBUG.log('doDeclareChallenge...reject', participant)
                that.hasWon = false
                if (!this.score) {
                    this.askForScore = true
                    that.$parent.showTableMessage('Please enter the score', true, true)
                    return false
                }
				that.error = null;
				try {
					const invokeArgs = {
						gameId: that.gameState.id,
                        gameDefId: that.gameState.gameDef.id,
						playerId: participant.id,
						hasWon: false,
						closeGame: false,
                        score: that.score
					};
					await that.closedAction_DeclareChallenge(invokeArgs);
					that.$parent.showTableMessage(this.getPlayerName(participant) + ' has made a wrong show and will be dropped from the game', true, true)
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred - ${error.message}`)
				}
			},
			
		}
	};
</script>
