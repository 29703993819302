<template>
		<div :class="['player', 'player' + (playerindex+1), 
						{ 	
							'playing' : isPlayerActive, 
							'dealer' : isPlayerDealer, 
							'active': isPlayerFocussed, 
							'show-joker' : hasPlayerSeenJoker, 
							'playingBlind': isPlayerPlayingBlind 
						}
					]"
			:id="playerId">
			
			<div class="addon-options">
				<span class="dealer" :title="getPlayerName(player) + ' is the dealer'" :style="dealerButtonSize"></span>
				<span class="joker"></span>
			</div>
			
			<slot name="video"></slot>
			<p :class="stackPosition(playerId)"> {{walletBalance}} </p>
			<p :class="handStatePosition(playerId)" v-if="isDropped"> {{droppedString()}} </p>
			<p :class="handStatePosition(playerId)" v-else-if="isNotDealt"> Waiting for Next Game </p>
			<p :class="handStatePosition(playerId)" v-else-if="isAllIn"> All In </p>
			<p class="actions-text" v-if="player.actionText">Hey user, its you turn to play!</p>
			<div class="buttonsRight" v-if="(isDealer || isHost) && playerindex != 0">
				<div v-if="canForceContinue()" title="Force skip player">
					<img class = "overlayButton" @click="forceContinue" src="../../assets/images/force-continue.png"/>
				</div>
				<div v-if="canForceDrop()" :title="forceDropString">
					<fa-icon class = "overlayButton" @click="forceDrop" icon="download"/>
				</div>
				<div v-if="sitOutAllowed(player)" title="Force Sit Out">
					<fa-icon :icon="['fas', 'sign-out-alt']" class="overlayButton" @click="sitOut(player, ParticipantStates.SITTINGOUT)"/>
				</div>
			</div>
			<div v-if="isPlayerDealer" class="dealerButton" :title="getPlayerName(player) + ' is the dealer'">
				<img  src="../../assets/images/DealerButton.png" :style="dealerButtonSize"/>
			</div>
			<div v-if="gameState.gameDef.rules.hasPot && roundBet > 0" class = "betChips">
				<img v-if="playerindex == 0" src="../../assets/images/chips.png"/>
				<img v-else src="../../assets/images/chips2.png"/>
				{{roundBet}} 
			</div>
		</div>
</template>
<script>
	import DEBUG from '@/common/DEBUG'
	import {ParticipantStates, ParticipantHandStates, TableStates, GameType} from '@/grpcservices/Game_pb';
	import gametransitions from "@/components/game/gametransitions";
	import { mapState, mapActions } from 'vuex'

	export default {
		name: "gameplayer",

		mixins: [
			gametransitions
		],

		props: {
			playerId: String,
			isPlayerFocussed: Boolean,
			isPlayerActive: Boolean,
			isPlayerDealer: Boolean,
			hasPlayerSeenJoker: Boolean,
			isPlayerPlayingBlind: Boolean,
			isWinner:Boolean,
			isDealer: Boolean,
			isHost: Boolean,
			player: {
				Type: Object,
				Required: true,
			},
			playerindex: {
				Type: Number,
				Required: true,
			},
			tableState: {
				Type: TableStates,
				Required: true,
			},
			videoStream: MediaStream,
			pauseVideo: Function,
			pauseAudio: Function,
		},

		computed: {
			...mapState(["userinfo", "gameState"]),

			meid() {
				return this.userinfo.id;
			},
			isDropped: function () {
				return this.player?.hand?.state == ParticipantHandStates.DROPPED
			},

			isAllIn: function () {
				return this.player?.hand?.state == ParticipantHandStates.ALLIN;
			},

			isNotDealt: function () {
				return (this.gameState.state == ParticipantStates.WAITINGTOPLAY || this.player?.hand?.state == ParticipantHandStates.NOTDEALT)
			},

			cycleBet: function() {
				return this.player.hand?.cycleBet ? this.player.hand?.cycleBet : 0
			},

			roundBet: function() {
				return this.player.hand?.roundBet ? this.player.hand?.roundBet : 0
			},

			gameBet: function() {
				return this.player.hand?.gameBet ? this.player.hand?.gameBet : 0
			},

			walletBalance: function() {
				return this.player.gameWallet ? this.player.gameWallet.balance : 0
			},

			dealerButtonSize: function() {
				return this.playerindex == 0 ? "max-width:100%" : "max-width:70%";
			},

			chipsButtonImage: function() {
				return this.playerindex == 0 ? "../../assets/images/chips.png" : "../../assets/images/chips2.png";
			},
			popoverConfig() {
				return {
					html: true,
					content: () => {
						return 'Your recent bet was ' + this.cycleBet + (this.roundBet > 0 ? ' of total ' +
						this.roundBet: '' ) + '. <br/> Your balance is ' + this.walletBalance
					}
				}
			},
			popoverData() {
				return {
					content: 'Your recent bet was ' + this.cycleBet + ' of total ' +
					this.roundBet + '. <br/> Your balance is ' + this.walletBalance
				}
			},
			forceDropString() {
				switch (this.gameState.gameDef.gameType) {
					case GameType.RUMMY: return "Force Drop player";
					case GameType.TEENPATTI: return "Force Pack player";
					case GameType.POKER: return "Force Fold player";
					default: return "Force fold player"
				}
			},
		},

		data() {
			return {
				TableStates: TableStates,
				balance: 0,
				ParticipantStates: ParticipantStates,
			}
		},

		methods: {
			...mapActions([
				"updateParticipantState",
			]),
			
			playerAction(id){
					this.$parent.playerAction(this.player, id);
			},
			canForceDrop() {
				if (this.tableState == TableStates.PLAY && this.isPlayerActive)
					return true;
				return false;
			},
			forceDrop() {
				if (this.canForceDrop()) {
					this.$parent.doFold(this.playerId)
				} else {
					this.$parent.showTableMessage('Please wait for ' + this.getPlayerName(this.player) + '\'s turn' , true, true)
				}
			},
			canForceContinue() {
				if (this.tableState == TableStates.PLAY && this.$parent.isRummy && this.isPlayerActive)
					return true;
				return false;
			},
			forceContinue() {
				if (canForceContinue()) {
					this.$parent.doPlayNext(this.playerId)
				} else {
					this.$parent.showTableMessage('Please wait for ' + this.getPlayerName(this.player) + '\'s turn' , true, true)
				}
			},
			declareWinner() {
				this.$parent.declareWinner(this.playerId);
			},
			droppedString() {
				switch (this.gameState.gameDef.gameType) {
					case GameType.RUMMY: return "DROPPED";
					case GameType.TEENPATTI: return "PACKED";
					case GameType.POKER: return "FOLD";
					default: return "FOLD"
				}
			},
			stackPosition(id) {
				if (id == this.meid)
					return 'stackMe';
				let videoPosition = this.videoPositionedOnUpperSide(id);
				if (videoPosition == 0)
					// video not on upper side
					return 'stackBtm';
				else if (videoPosition == 1)
					// video on upper side
					return 'stackTop';
				// not able to determine video position
				return (id == this.meid) ? 'stackMe' : 'stackTop';
			},
			betPosition(id) {
				let videoPosition = this.videoPositionedOnUpperSide(id);
				if (videoPosition == 0)
					// video not on upper side
					return 'betBtm';
				else if (videoPosition == 1)
					// video on upper side
					return 'betTop';
				// not able to determine video position
				return (id == this.meid) ? 'betBtm' : 'betTop';
			},
			handStatePosition(id) {
				let videoPosition = this.videoPositionedOnUpperSide(id);
				if (videoPosition == 0)
					// video not on upper side
					return 'handStateBtm';
				else if (videoPosition == 1)
					// video on upper side
					return 'handStateTop';
				// not able to determine video position
				return (id == this.meid) ? 'handStateBtm' : 'handStateTop';
			},
		}
	};
</script>
