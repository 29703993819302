<template>
	<div class="videocontainer" style="border: 1px dashed blue;transform:scale(-1, 1);">
		<center class="pt-3">
			<fa-icon class="text-danger fa-3x success" icon="spinner" spin show />
		</center>
		<div :id="videoId">
		</div>
	</div>
</template>

<script>
	export default {
		name: "Video",
		props: {
			videoId: String,
		}
	}
</script>
<style lang="scss" scoped>
	.videocontainer {
		background-color: #353535;
		height: 100%;
		position: relative;
	}
</style>