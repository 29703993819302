<template  v-if="(gameState.tableState == TableStates.DEAL && checkIfDealer) ||
						(gameState.tableState == TableStates.PLAY && 
									gameState.jokerTray.cards.length>0)">
	<basetray :tray="tray" :displayType="'stack'" :onAddItem="onAddItem" :isFaceDown="false"
		:className="'joker-'" :draggable="false" :droppable="allowDealJoker" :trayName="trayName"
		 :customClass="'joker jokerDeck'">
		<template v-slot:addOn>
			<b-dropdown size="sm" class="joker-dropdown" :text="currentJoker" 
				v-if="checkIfDealer && gameState.tableState == TableStates.DEAL && tray.cards.length == 0">
				<b-dropdown-item class="m-0" @click="changeJokerType(JokerTypes.NOJOKER)">No Joker</b-dropdown-item>
				<b-dropdown-item style="margin:0;padding:2px;" @click="changeJokerType(JokerTypes.CLOSED)">Closed</b-dropdown-item>
				<b-dropdown-item class="m-0" @click="changeJokerType(JokerTypes.OPEN)">Open</b-dropdown-item>
			</b-dropdown>
		</template>
		<template v-slot:title>
			<p class="title">{{title}}</p>
		</template>
	</basetray>
</template>
<script>
	import basetray from "@/components/game/basetray";
	import trayutils from "@/components/game/trayutils";
	import { TrayTypes, TableStates, JokerTypes} from '@/grpcservices/Game_pb';
	import DEBUG from '@/common/DEBUG'
	import { mapActions } from 'vuex'

	export default {
		name: "jokertray",

		components: {
			basetray
		},

		mixins: [
			trayutils
		],

		props: {
			tray: {
				Type: Object
			},
			trayName: {
				Type: String
			},
			title:{
				Type: String,
				default:"Joker"
			},
		},

		data() {
			return {
				TableStates: TableStates,
				JokerTypes: JokerTypes,
				pickCardInProgress: false, 
			}
		},

		computed: {
			allowDealJoker() {
				if (this.gameState.gameDef?.rules?.hasJokerTray && this.checkIfDealer) {
					if(!this.gameState?.jokerTray?.cards) {
						return true
					}
					if(!this.gameState?.jokerTray?.cards.length) {
						return true
					}
				}
				return false
			},

			currentJoker() {
				let jokerVal = this.gameState.gameDef?.rules?.jokerType
				if(jokerVal == JokerTypes.CLOSED) {
					return 'Closed'
				}
				if(jokerVal == JokerTypes.OPEN) {
					return 'Open'
				}
				if(jokerVal == JokerTypes.NOJOKER) {
					return 'No Joker'
				}
				return 'Select'
			}
		},

		methods: {
			...mapActions(["updateGameState", "updateJokerType"]),

			onAddItem: function(selectedIndex) {
				DEBUG.log('Joker card dropped on tray', selectedIndex, this.getGlobalVar('$sourceTray'))
				// check source tray and if is dealer
				if (this.getGlobalVar('$sourceTray') == this.trayName || this.getGlobalVar('$sourceTray') != TrayTypes.DEAL_TRAY) {
					return false
				}
				this.pickJokerCard(selectedIndex)
			},

			async pickJokerCard(selectedIndex) {
				const that = this	
				try {
					that.meplayer.sourceTrayType = this.getGlobalVar('$sourceTray')
					that.meplayer.targetTrayType = this.trayName
					that.meplayer.sourceCardIndex = selectedIndex

					await that.doMoveAction(that.gameState, that.meplayer)
					that.error = null
				} catch (error) {
					this.pickCardInProgress = false
					that.error = `unable to pick joker card - ${error.message}`
					that.notifyError(error.message)
				}	
			},
			checkMove() {
				DEBUG.log('Joker card moved')
				return false
			},

			async changeJokerType(jokerType) {
				const that = this
				that.error = null;

				try {
					const invokeArgs = {
						gameId: that.gameState.id,
						playerId: that.meplayer.id,
						jokerTypeValue: jokerType,
					};
					DEBUG.log('doUpdateJokerType...', invokeArgs)
					await that.updateJokerType(invokeArgs);
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred while updating joker type - ${error.message}`)
				}
			},
		}
	};
</script>