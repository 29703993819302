import DEBUG from '@/common/DEBUG';
import { mapActions } from 'vuex'
import { isMobile } from '@/utils'

// rtc
var { connect, createLocalTracks, LocalDataTrack, Logger } = require('twilio-video');

const twvideo = {
	data() {
		return {
			CONNECTOPTIONS: {
				dominantSpeaker: true,
			},
			AUDIOVIDEOOPTIONS: {
				name: 'av',
				audio: true,
				video: true,
			},
			DATAOPTIONS: {
				name: 'message',
				ordered: true,
				reliable: true,
			},

			gameid: null,
			room: null,
			localTracks: null,
			dataTrack: null,
			onMessageCallback: null,
			onFocusPlayerChanged: null,
		}
	},

	beforeDestroy() {
		this.onMessageCallback = null;
		this.onFocusPlayerChanged = null;
		this.leaveRoomIfJoined();
	},

	methods: {
		...mapActions([
			"getMyGameVideoToken",
		]),

		getPlayerVideoId(userid) {
			return `video-${userid}`;
		},

		async initRTC(rtcInfo) {
			const that = this;
			const { meid, gameId, videoEnabled, textEnabled, onMessageCallback, onFocusPlayerChanged } = rtcInfo;
			that.onMessageCallback = onMessageCallback;
			that.onFocusPlayerChanged = onFocusPlayerChanged;

			// init once elements are available in DOM...
			DEBUG.log(`${meid} joining room ${gameId}...`);

			that.$nextTick(async () => {
				const localTracks = that.localTracks = (videoEnabled ? await createLocalTracks(that.AUDIOVIDEOOPTIONS) : []);
				const dataTrack = that.dataTrack = (textEnabled ? new LocalDataTrack(that.DATAOPTIONS) : null);
				if (dataTrack) {
					localTracks.push(dataTrack);
				}

				try {
					const token = await this.getMyGameVideoToken({ gameId });
					DEBUG.log(`user token - User=${this.meid} Game=${gameId} Token=${token.value}`)

					var logger = Logger.getLogger('twilio-video');
					//logger.setLevel('debug');
					logger.setLevel('warn');

					var connectOptions = {
						name: gameId,
						tracks: localTracks,

						...that.CONNECTOPTIONS
					};

					DEBUG.log('VIDEO CONNECT OPTIONS - ', connectOptions)
					connect(token.value, connectOptions)
						.then(that.roomJoined, (error) => {
							DEBUG.error(`ERROR connecting to room - ${gameId} -`, error);
							that.showTableMessage('Twilio Error - ERROR connecting to room', false, true);
						})
				} catch (error) {
					DEBUG.error('ERROR getting a token for the game - ', error);
				}
			})
		},

		roomJoined(room) {
			const that = this
			this.room = room;
			DEBUG.log("Joined as '" + this.meid + "'");

			function updatePlayerLoaded(div, isLoaded) {
				const playerVideo = $(div).closest('playerVideo');
				if (isLoaded) {
					playerVideo.addClass("video-loaded");
				} else {
					playerVideo.removeClass("video-loaded");
				}
			}

			function clearChildren(div) {
				if (div) {
					while (div.firstChild) {
						div.removeChild(div.firstChild);
					}
				}
			}

			function getTracks(participant) {
				return Array.from(participant.tracks.values()).filter(function (publication) {
					return publication.track;
				}).map(function (publication) {
					return publication.track;
				});
			}

			function getUserVideoElement(participantid) {
				return document.getElementById(that.getPlayerVideoId(participantid));
			}

			function participantTrackSubscribed(track, publication, participant) {
				DEBUG.log(`publishing ${track.kind} track - `, track, publication, participant);
				if (track.kind != 'data') {
					const div = getUserVideoElement(participant.identity);
					DEBUG.log('publishing track in element - ', track, div);

					div.appendChild(track.attach());
					updatePlayerLoaded(div, true);
				}
			}

			function participantTrackUnsubscribed(track, publication, participant) {
				DEBUG.log('unpublishing track - ', track);
				if (track.kind != 'data') {
					track.detach().forEach(element => {
						clearChildren(element);
						updatePlayerLoaded(element, false);
					});
				}
			}

			function participantConnected(participant) {
				DEBUG.log('participant "%s" connected', participant.identity);
				participant.tracks?.forEach((publication) => {
					if (publication.track) {
						participantTrackSubscribed(publication.track, publication, participant);
					}
				});
			}

			function participantDisconnected(participant) {
				DEBUG.log('participant "%s" disconnected', participant.identity);
				getTracks(participant).forEach((track) => participantTrackUnsubscribed(track, null, participant));
				const div = getUserVideoElement(participant.identity);
				clearChildren(div);
			}

			function domainParticipantChange(participant) {
				const playerIdentity = participant?.identity
				DEBUG.log('A new participant is now the dominant speaker:', playerIdentity);
				if (that.onFocusPlayerChanged) {
					that.onFocusPlayerChanged(playerIdentity);
				}
			}

			// video/audio
			participantConnected(room.localParticipant);
			room.participants.forEach(participantConnected);

			room.on('dominantSpeakerChanged', domainParticipantChange);

			room.on('participantConnected', participantConnected);
			room.on('participantDisconnected', participantDisconnected);

			room.on('trackSubscribed', (track, publication, participant) => {
				DEBUG.log('TRACK SUBSCRIBED - ', track, publication, participant);
				participantTrackSubscribed(track, publication, participant);
			});
			room.on('trackUnsubscribed', (track, publication, participant) => {
				DEBUG.log('TRACK UNSUBSCRIBED - ', track, publication, participant);
				participantTrackUnsubscribed(track, publication, participant);
			});

			room.on('trackEnabled', (publication, participant) => {
				DEBUG.log('trackEnabled - ', publication, participant);
			});
			room.on('trackDisabled', (publication, participant) => {
				DEBUG.log('trackDisabled - ', publication, participant);
			});

			room.on('trackSwitchedOff', (track, publication, participant) => {
				DEBUG.log('trackSwitchedOff - ', track, publication, participant);
			});

			room.on('trackSwitchedOn', (track, publication, participant) => {
				DEBUG.log('trackSwitchedOn - ', track, publication, participant);
			});

			room.on('trackMessage', (data, track, participant) => {
				DEBUG.log('trackMessage - ', data, participant, this.onMessageCallback);
				if (this.onMessageCallback) {
					this.onMessageCallback(JSON.parse(data), participant);
				}
			});
			room.once('disconnected', error => {
				DEBUG.log('room disconnected - ERROR - ', error);
				room.participants.forEach(participantDisconnected);
			});

			// Leave the Room when the "beforeunload" event is fired.
			window.onbeforeunload = () => {
				room.disconnect();
			};

			const isMobileBrowser = isMobile();
			if (isMobileBrowser) {
				// TODO(mmalavalli): investigate why "pagehide" is not working in iOS Safari.
				// In iOS Safari, "beforeunload" is not fired, so use "pagehide" instead.
				window.onpagehide = () => {
					room.disconnect();
				};

				// On mobile browsers, use "visibilitychange" event to determine when
				// the app is backgrounded or foregrounded.
				document.onvisibilitychange = async () => {
					if (document.visibilityState === 'hidden') {
						const localParticipant = room.localParticipant;

						// When the app is backgrounded, your app can no longer capture
						// video frames. So, stop and unpublish the LocalVideoTrack.
						// do we need to stop only video?
						that.localTracks?.filter(track => track.kind != 'data').forEach(localTrack => {
							DEBUG.log('localTrack - ', localTrack)
							localTrack.stop()
							localParticipant.unpublishTrack(localTrack);
						});
					} else {
						// When the app is foregrounded, your app can now continue to
						// capture video frames. So, publish a new LocalVideoTrack.
						const localTracks = that.localTracks = await createLocalTracks();
						localTracks.forEach(async localTrack => {
							await localParticipant.publishTrack(localTrack);
						});
					}
				};
			}


			room.once('disconnected', (room, error) => {
				// Clear the event handlers on document and window..
				window.onbeforeunload = null;
				if (isMobileBrowser) {
					window.onpagehide = null;
					document.onvisibilitychange = null;
				}

				// Stop the LocalVideoTrack.
				that.localTracks?.filter(track => track.kind != 'data').forEach(localTrack => {
					DEBUG.log('localTrack - ', localTrack)
					localTrack.stop();
				});

				// Handle the disconnected LocalParticipant.
				participantDisconnected(room.localParticipant, room);

				// Handle the disconnected RemoteParticipants.
				room.participants.forEach(participant => {
					participantDisconnected(participant, room);
				});

				// Clear the Room reference used for debugging from the JavaScript console.
				that.room = null;
			});
		},

		async broadcastRawMessage(message) {
			await this.dataTrack.send(JSON.stringify(message));
		},
		async broadcastTextMessage(text) {
			await this.dataTrack.send(JSON.stringify({
				type: 'text',
				message: text
			}));
		},

		pauseVideo(on) {
			if (this.room) {
				this.room.localParticipant.videoTracks.forEach(publication => {
					if (!on) publication.track.disable();
					else publication.track.enable();
				});
			}
		},

		pauseAudio(on) {
			if (this.room) {
				this.room.localParticipant.audioTracks.forEach(publication => {
					if (!on) publication.track.disable();
					else publication.track.enable();
				});
			}
		},

		leaveRoomIfJoined() {
			this.localTracks?.filter(track => track.kind != 'data').forEach(localTrack => {
				DEBUG.log('localTrack - ', localTrack)
				localTrack.stop();
			});
			this.localTracks = null;

			const room = this.room;
			if (room) {
				room.localParticipant.videoTracks.forEach(publication => {
					publication.track.stop();
					publication.unpublish();
				});

				room.disconnect();
				this.room = null;
			}
		},
	},
}

export {
	twvideo
}