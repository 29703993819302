<template>
    <b-modal ref="scorereportModal"  hide-footer class="modal-md">
        <template #modal-header="{ close }">
            <h2>{{gameState.gameDef.name}}</h2>
            <hr/>
            <h3>
                Player hand position after {{gameState.gameNumber}} game(s) - 
            </h3>
            <button size="sm" class="close" @click="close">×</button>
        </template>
        <template v-if="gameState && (gameState.gameNumber > 1 || gameState.tableState > TableStates.PLAY)">
            <table>
                <tr>
                    <th> Name </th> 
                    <th> eMail </th> 
                    <th> Buy In </th>
                    <th> Balance </th>
                    <th> Position in {{gameState.currency}} </th>
                </tr>
                <template v-for="(p, pid) in gameState.participants" style="display:flex;height:75px;"> 
                    <tr v-if="p.gameWallet != null" :key="pid" > 
                        <td>{{p.userDisplayName}}</td> 
                        <td>{{p.email}}</td>
                        <td>{{totalBuyIn(p)}}</td>    
                        <td>{{walletBalance(p)}}</td>
                        <td>{{position(p)}}</td>
                    </tr>
                </template> 
            </table>
        </template>
    </b-modal>
</template>

<script>
    import { mapState, mapActions} from 'vuex';

    import {
		TableStates, 
        GameType
	} from '@/grpcservices/Game_pb';

	export default {
		name: "scorereport",
		props: {
            
		},

		computed: {
            ...mapState(["userinfo", "gameState","gameDef",]),  
            netPot(){
                return this.gameState.participants
                                .map(item => (item.gameWallet.balance-item.gameWallet.totalBuyIn))
                                .reduce((prev, curr) => prev + curr, 0);
            },
            loggedInPlayer() {
                return _.find(this.gameState.participants, {id: this.userinfo.id});
            },
            winningHandScore() {
                return this.gameState.pot.netAmount;
            },
           
		},

		data() {
			return {
                TableStates: TableStates,
                GameType: GameType
			}
		},

		mounted() {
			
		},

        methods: {
            show() {
				this.$refs.scorereportModal.show();
			},

            hide() {
				this.$refs.scorereportModal.hide();
			},
            walletBalance(p) {
				return p.gameWallet != null ? p.gameWallet.balance : 0
			},
            totalBuyIn(p){
                return p.gameWallet != null ? p.gameWallet.totalBuyIn : 0
            },
            position(p){
                return this.walletBalance(p) - this.totalBuyIn(p);
            },
        }
	};
</script>
<style scoped>
</style>
