<template>
	
	<div class="controls">
		<span @click="swapVideo()"
		:class="[{ disabled: !videoOn }, 'px-4']" style="margin-left:25px;">
		<fa-icon v-if="videoOn" icon="video" show />
		<fa-icon v-else icon="video-slash" show />
		</span>
		<span @click="swapAudio()"
			:class="[{ disabled: !audioOn }, 'px-4']">
			<fa-icon v-if="audioOn" icon="microphone" show />
			<fa-icon v-else icon="microphone-slash" show />
		</span>
	</div>
</template>

<script>
	export default {
		name: "audiovideocontrols",
		props: {
			pauseVideo: Function,
			pauseAudio: Function
		},
		data: () => ({
			videoOn: true,
			audioOn: true,
		}),
		methods: {
			swapVideo() {
				this.videoOn = !this.videoOn
				this.pauseVideo(this.videoOn)
			},
			swapAudio() {
				this.audioOn = !this.audioOn
				this.pauseAudio(this.audioOn)
			}
		}
	}
</script>
<style lang="scss" scoped>
	.controls 
	{
		position: absolute;
		bottom: 5px;
		display: flex;
		width: 100%;
		justify-content: center;
		span

		{
			&:hover
			{
				background: #717171;
			}

			border: solid 1px white;
			border-radius: 17px;
			padding: 4px;
			z-index: 10;
			bottom: 0;
			display: block;
			color: white;
			margin: 0 5px 5px 5px;
			cursor: pointer;

			&.disabled {
				background-color: red;
				border: solid 1px red;
				&:hover
				{
					background-color: red;
				}
			}
		}
	}
</style>