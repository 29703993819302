<template>
	<span class="table-input">
		<textarea type="number" v-model.trim="score" placeholder="" name="score"/>
		<!-- <button class="table-action-button btn gold-button" @click="doDeclareScore(player)">
			Declare
		</button> -->
	</span>
</template>
<script>
	import DEBUG from '@/common/DEBUG'
	import gametransitions from "@/components/game/gametransitions";
    import { mapState, mapActions } from 'vuex';

	export default {
		name: "scorebox",

		mixins: [
			gametransitions
		],

		props: {
			player: Object,
			score: Number
		},

		computed: {
            ...mapState(["gameState"]),
		},

		data() {
			return {
				score: ''
			}
		},

		mounted() {
			//DEBUG.log('PLAYER...', this.player)
		},

		methods: {
            ...mapActions(["closedAction_DeclareScore"]),

            async doDeclareScore() {
				const that = this
				

				if(!this.score) {
					//this.showTableMessage('Please add the score', true)
					return false
				}

				that.error = null;
				try {
					const invokeArgs = {
						gameId: that.gameState.id,
						forPlayerId: that.player.id,
						score: that.score,
					};
					DEBUG.log('doDeclareScore...', invokeArgs)
					await that.closedAction_DeclareScore(invokeArgs);
					this.score = null
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred - ${error.message}`)
				}
			},
			
		}
	};
</script>
