<template> 
	<basetray :tray="tray" :displayType="currentDisplay" :onAddItem="onAddItem" :isFaceDown="true" 
		:className="'deal-'" :checkMove="checkMove" 
		:onClickCallback="(idx) => handleCardClick(idx)">
		<template v-slot:title>
		</template>
	</basetray>
		<!--button v-on:click="toggleDisplayType()">Toggle</button-->
</template>

<script>
	import basetray from "@/components/game/basetray";
	import trayutils from "@/components/game/trayutils";
	import DEBUG from '@/common/DEBUG'
	import { TrayTypes } from '@/grpcservices/Game_pb';

	export default {
		name: "decktray",

		components: {
			basetray
		},

		mixins: [
			trayutils
		],

		props: {
			tray: {
				Type: Object
			},
			trayName: {
				Type: String
			},
			displayType: {
				Type: String
			}
		},

        data() {
			return {
				pickCardInProgress: false, 
				currentDisplay: ''
			}
		},

		computed: {
			allowPickSeat() {
				/*if (this.gameDef && this.gameDef.rules.mustSeatDeal == false) {
					return true
				}*/
				if (!this.isActivePlayer) {
					return false
				}
				if (this.meplayer && !this.meplayer?.seatPickTray?.cards.length) {
					return true
				}
				return false
			},

		},
		mounted() {
			this.currentDisplay = this.displayType
		},
		
		methods: {
			
			onAddItem: function() {
				return false
			},

			async pickSeatCard(selectedIndex) {
				const that = this
				if (!this.allowPickSeat) {
					return false
				}
				try {

					that.meplayer.sourceTrayType = this.trayName
					that.meplayer.targetTrayType = TrayTypes.SEAT_PICK_TRAY
					that.meplayer.sourceCardIndex = selectedIndex

					await that.doMoveAction(that.gameState, that.meplayer)
					that.error = null
				} catch (error) {
					this.pickCardInProgress = false
					that.error = `unable to seat the players - ${error.message}`
					that.notifyError(error.message)
				}	
			},

			checkMove() {
				return true
			},
			
			handleCardClick(selectedIndex) {
				this.pickSeatCard(selectedIndex)
			},

			handleCardDblClick(selectedIndex) {
				this.pickSeatCard(selectedIndex)
			}
		}
	};
</script>