<template >
	<div class="card-list" :class="[isFaceDown ? 'deal' : 'joker']">
			<div class="d-flex" v-if="isMounted" style="height:100px;justify-content:space-between;align-content:space-between;">
				<div v-if="gameState.tableState >= TableStates.PLAY" class="hand-score" >{{cards.systemCalculatedScore}}</div>
				<template v-for="(groupObj, idx) in groupedCards">
					<!-- <div class="group-score" v-if="groupObj.score > -1" :key="groupObj.group">{{groupObj.score}}</div> -->
					<!-- <b-overlay :style="'width:'+getGroupWidth(groupObj)+'%;min-width:66px;'"
						:show="isGroupShared(groupObj)" :variant="'secondary'" :opacity="'0.35'" :blur="'2px'" rounded="sm" :key="idx">	 -->
						<draggable class="my-list-group" :list="groupObj.cards" v-bind="dragOptions"  :sort="true" 
							@add="onDrop" :move="onDrag" @end="onEnd" :component-data="getComponentData()" force-fallback="true" 
							:key="`my-group-main-` + groupObj.group" :id="groupObj.group" :disabled="disabled" 
							style="height:100%;width:100%;overflow:hidden;display:inline-block">
								<playingcard v-for="(card, cardIndex) in groupObj.cards" 
									:key="`my-list-` + groupObj.group + cardIndex"  		
									:suit="card.suit"
									:rank="card.rank" 
									:cardId="card.id" 
									:onClickCallback="() => handleClick(card)"  
									:isSelectedCard="isSelectedCard(card.id)" 
									:isFaceDown="!card.isFaceUp"
									:style="cardStyleWithOffset(groupObj,cardIndex)"
									ref="vcards"
									:toggle="trigger_toggle"
								>
									<template v-slot:cardActions>
										<div class="card-action" v-if="selectedCardId == card.id && !disabled">
											<!-- <template v-if="selectedCards.length > 1">
												<button class="btn btn-group" v-if="selectedCards.length > 1" v-on:click.stop="createGroup">Group</button>
											</template> -->
											<template v-if="canShowDiscard">
												<span  class="btn btn-discard" style="padding:5px;color:red">
													<fa-icon v-if="gameState.gameDef.rules.hasDiscardTray"  
														:icon="['fas', 'times']" class="icon"
														v-on:click.stop="discard(card.id)" title="Discard"/>				
												</span> 
												<span class="btn btn-declare"  style="padding:5px;color:green">
													<fa-icon v-if="gameState.gameDef.rules.hasDeclareTray"
														:icon="['fas', 'mitten']" class="icon"
														v-on:click.stop="doShow(card.id)" title="Declare"/>
												</span> 
											</template>
										</div>
									</template>
								</playingcard>
						</draggable>
						<!-- <template #overlay v-if="gameState.tableState < TableStates.SHOW">
							<div class="text-center">
								<b-button
									ref="cancel"
									variant="danger"
									size="sm"
									aria-describedby="cancel-label"
									@click="showCards(groupObj.group, null, false, true)">
									Cancel Share 
								</b-button>
							</div>
						</template> -->
					<!-- </b-overlay> -->
				</template>
			</div>
		</div>
</template>

<script>
	import playingcard from "@/components/game/playingcard";
	import draggable from "vuedraggable";
	import trayutils from "@/components/game/trayutils";
	import DEBUG from '@/common/DEBUG'
	import { TrayTypes, TableStates } from '@/grpcservices/Game_pb';
	import gametransitions from "@/components/game/gametransitions";
	import { mapState, mapActions } from 'vuex'

	export default {
		name: "playerhand",

		mixins: [
			gametransitions,
			trayutils
		],
		components: {
			playingcard,
			draggable
		},

		props: {
			cards: {
				Type: Array
			},
			isFaceDown : {
				type: Boolean,
				default: false,
			},
			trayName: {
				type: Number
			},
			disabled : {
				type: Boolean,
				default: false,
			},
			isPlayingBlind: {
				type: Boolean,
				default: false
			},
		},

		computed: {
			dragGroupOptions() {
				return {
					animation: 200,
					group: "group",
					disabled: false,
					ghostClass: "ghost",
				};
			},
			allCards(){
				var allcardslist = this.cards.groups.flatMap((grp)=>grp.cards);
				console.log("getting all cards for ", allcardslist);
				return  allcardslist;
			},
			groupedCards: {
				get() {
					return this.cards.groups
				},

				set(val) {
					DEBUG.log('Need to update store - allCards', val)
					//this.$store.commit('setExampleList', val)
				}
			},
			canShowDiscard() {
				if (!this.isActivePlayer) {
					return false
				}
				if (this.selectedCards.length > 1) {
					console.log("More than one card selected...",this.selectedCards);
					return false
				}
				var allcardslist = this.allCards;
				var maxCardsAllowed = this.cards.props.maxCardsCount;
				
				console.log("canShowDiscard ",this.selectedCards," of cards ", allcardslist.length," allowewd max cards ",maxCardsAllowed);
				return allcardslist.length > maxCardsAllowed;
			},

			isSorted() {
				return this.cards.groups.length > 1
			},
		},
		data() {
			return {
				TableStates: TableStates,
				pickCardInProgress: false, 
				showActions: false,
				selectedCards: [],
				selectedCardId: null,
				isMounted:false,
				dragOptions: {
					animation: 200,
					pull: 'clone',
					group: "cards",
					disabled: false,
					ghostClass: "ghost",
					put: true
				},
				trigger_toggle:0
			};
		},
		mounted() {
			console.log('Mounted', this.cards, this.isFaceDown)
			this.isMounted = true
		},
		methods: {
			...mapActions(["openAction_Rearrange", "openAction_MeldBySuit", "closedAction_PlayerHandShareCardGroupArguments", "openAction_UnblindHandCards"]),
			cardStyleWithOffset(groupObj, index)
			{
				var cardCount = groupObj.cards.length;
				var groupWidth = this.getGroupWidth(groupObj);
				var parentWidth = $('.game-container').width()*(groupWidth/100);	

				// console.debug("cardStyleWithOffset - group with "+cardCount+" cards & width "+groupWidth+"% parent width:"+parentWidth);

				var cardWidth = 66;
				var cstyle;
				if(parentWidth < cardWidth*cardCount)
					cstyle = "position:absolute;min-width:66px;left:"+index*100*(1/cardCount)+"%";
				else {
					var leftPos = (parentWidth - cardWidth*cardCount)/2 + index*cardWidth;
					cstyle = "position:absolute;min-width:66px;left:"+leftPos+"px";
				}
				// console.debug("cardStyleWithOffset - card ("+index+") Style  ",cstyle);
				return cstyle;
			},
			doShow(cardId){
				this.$parent.doShow(cardId);

				// const toggleElement = this.$refs['vcards'].find(
				// el => el.$attrs['cardId'] === cardId);
				// console.log("Toggle selection for declared card", toggleElement, " card: ", cardId);
				// toggleElement.clearSelection();

				this.selectedCards.length = 0;
				this.selectedCardId = null
			},
			getCardWidth(cardGroup) {
				var containerWidth = this.getGroupWidthInPX(cardGroup);
				var cardWidth = containerWidth / cardGroup.cards.length;
				return cardWidth;
			},
			getGroupWidthInPX(groupObj) {
				if ($('.game-container').length) {
					var containerWidth = $('.game-container').width()*.75;
					var width =  (containerWidth / this.currentPlayerHandCardCount) * groupObj.cards.length;
					return width;
				}
				return 0
			},
			getGroupWidth(groupObj) {
				if ($('.game-container').length) {
					var containerWidth = $('.game-container').width();
					var width =  Math.max(66,(containerWidth / this.currentPlayerHandCardCount) * groupObj.cards.length);
					return Math.round((width/containerWidth)*100 - 0.4);
				}
				return 0
			},
			getComponentData() {
				return {
					trayName: TrayTypes.PLAYERHAND_TRAY
				}
			},
			isGroupShared(gp) {
				return gp && (gp.sharedWith.length > 0 || gp.sharedWithAll);
			},
			isSelectedCard(cardId) {
				var cardSelected = this.selectedCards.length ? _.find(this.selectedCards, function(o) { return o.id == cardId ;}) : false;
				return cardSelected;
			},
			onDrop: function(evt) {
				var droppedfrom = this.getGlobalVar('$sourceTray');
				// if dropped from same tray, do not make server call
				if (droppedfrom == this.trayName) {
					return false	
				}
				this.setGlobalVar('$targetTray', this.trayName)
				if (droppedfrom == TrayTypes.DEAL_TRAY || droppedfrom == TrayTypes.DISCARD_TRAY) {
					this.pickCard(evt?.to?.id)
					return false
				}
			},
			onDrag: function(evt) {
				this.setGlobalVar('$sourceTray', this.trayName)
				var draggedEle = evt?.draggedContext?.element?.id

				if (draggedEle) {
					this.setGlobalVar('$selectedCardId', draggedEle)
				}
				
				let futureDragTray = evt?.relatedContext?.component?.componentData?.trayName;

				if(!this.isActivePlayer && futureDragTray != TrayTypes.PLAYERHAND_TRAY) { 
					return false
				}
				
				if(futureDragTray == TrayTypes.DEAL_TRAY ) {
					return false
				}

				if(this.currentPlayerHandCardCount == this.gameState.gameDef.rules.cardsPerHand) {
					if(futureDragTray == TrayTypes.DISCARD_TRAY ) {
						this.showTableMessage('Pick a card before discard', true)
						return false
					}
					if(futureDragTray == TrayTypes.DECLARE_TRAY ) {
						this.showTableMessage('Pick a card before declare', true)
						return false
					}
				}
				return true;
			},
			// onGroupDropEnd: function(evt) {
			// 	const that = this
				
			// 	DEBUG.log("On End: values", evt);
			// 	var groupToShow = evt.clone.id.replace('g-', '')
			// 	var playerToShow = evt.to.id.replace('s-', '');
			// 	DEBUG.log('Group to show', groupToShow)
			// 	that.showCards(groupToShow, playerToShow, false, false)
			// },

			onEnd: function(evt) {
				console.log('PlayerHand Drop End')

				let futureDragTray = evt?.relatedContext?.component?.componentData?.trayName;
				console.log('PlayerHand Drop End ', futureDragTray)
				
				const that = this
				if (that.getGlobalVar('$sourceTray') == that.trayName) {
					DEBUG.log("On End: values", evt.newIndex, evt.oldIndex, evt.from.id, evt.to.id);
					if (evt.from.id == evt.to.id) {
						// move within groups
						DEBUG.log('Element moved within same group', evt.from.id, evt.to.id)
					} else {
						DEBUG.log('Element moved across groups', this.groupedCards)
					}
					if(evt.from.id && evt.to.id) {
						that.doRearrangeHand(that.groupedCards)
					}
				}
			},

			handleClick(card) {
				if(this.disabled) {
					return false
				}
				
				if(this.isSelectedCard(card.id)) {
					var idx = _.findIndex(this.selectedCards, function(o) { return o.id == card.id ;})
					this.selectedCards.splice(idx, 1);
			
					if (this.selectedCards.length == 0) {
						this.selectedCardId = null
					} else {
						this.selectedCardId = this.selectedCards[this.selectedCards.length - 1].id
					}
					this.$parent.cardToDeclareOrDiscard(null);
				} else {					
					this.selectedCards.length = 0; // just have a single selection	
					this.selectedCardId = card.id
					this.selectedCards.push(card);
					this.$parent.cardToDeclareOrDiscard(card);
				}		
			},

			createGroup() {				
				const that = this				
				let groupId = this.generateUniqueId()
				let newGroup = {
					cards: [],
					group: groupId
				};

				let currentCards = JSON.parse(JSON.stringify(this.groupedCards))
				_.each(this.selectedCards, function(c) {
					
					currentCards.forEach(function(group) {
						group.cards = group.cards.filter(card => card.id != c.id);
					});

					c.group = groupId
					newGroup.cards.push(c)
				})

				this.selectedCards.length = 0;
				this.selectedCardId = null
				
				currentCards.push(newGroup)
				this.doRearrangeHand(currentCards)

				that.$refs['vcards'].forEach(function(card){
					card.clearSelection();
				})
			},

			async doRearrangeHand(cards) {
				if(this.disabled) {
					return false
				}
				const that = this
				that.error = null;
				try {
					const invokeArgs = {
						gameId: this.gameState.id,
						playerId: this.meplayer.id,
						groups: cards
					};
					DEBUG.log('doRearrangeHandCall...', invokeArgs)
					let updatedGameState = await that.openAction_Rearrange(invokeArgs)
					//await that.updateGameStateFlags(updatedGameState)
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred rearrange- ${error.message}`)
				}
			},

			async pickCard(targetGroupName) {
				const that = this
				try {
					DEBUG.log('Draw card', this.trayName)
					that.meplayer.sourceTrayType = this.getGlobalVar('$sourceTray')
					that.meplayer.targetTrayType = this.trayName
					if (targetGroupName) {
						that.meplayer.targetGroupName = targetGroupName
					}
					that.meplayer.sourceCardIndex = 0

					await that.doMoveAction(that.gameState, that.meplayer)

					//move errors to provide/inject
					that.error = null
				} catch (error) {
					that.error = `unable to pick card from deck - ${error.message}`
					that.notifyError(error.message)
				}	
			},

			async discard(cardId) {
				const that = this
				if(this.disabled) {
					return false
				}

				if(this.currentPlayerHandCardCount == this.gameState.gameDef.rules.cardsPerHand) {
					this.showTableMessage('Pick a card before discard or declare', true, true)
					return false
				}
				
				try {
					that.meplayer.sourceTrayType = this.trayName
					that.meplayer.targetTrayType = TrayTypes.DISCARD_TRAY;
					that.meplayer.sourceCardId = cardId
					
					// const toggleElement = that.$refs['vcards'].find(
					// 				el => el.$attrs['cardId'] === cardId);
					// console.log("Toggle selection for discarded card", toggleElement, " card: ", cardId);
					// toggleElement.toggleSelection();

					var idx = _.findIndex(this.selectedCards, function(o) { return o.id == cardId ;})
					this.selectedCards.splice(idx, 1);

					if (that.meplayer.sourceCardId) {
						await that.doMoveAction(that.gameState, that.meplayer)
					}

					//move errors to provide/inject
					that.error = null
					this.selectedCards.length = 0;
					this.selectedCardId = null
				} catch (error) {
					this.pickCardInProgress = false
					that.error = `pick a card before discard - ${error.message}`
					that.notifyError(error.message)
				}	
			},

			async sortCards() {
				const that = this
				that.error = null;
				try {
					const invokeArgs = {
						gameId: that.gameState.id,
						playerId: that.meplayer.id,
					};
					DEBUG.log('sortCards...', invokeArgs)
					let updatedGameState = await that.openAction_MeldBySuit(invokeArgs)
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred -sort - ${that.error.message}`)
				}
			},

			showHandToAll() {
				const that = this
				//TO-DO - refactor backend to support share entire hand
				//making multiple api calls per group, assuming there is only one group for teen patti - okay for now
				if (this.groupedCards?.length > 0) {
					_.each(this.groupedCards, function(groupObj) {
						that.showCards(groupObj.group, null, true, false);
					})
				}
			},

			async showCards(group, sharedWith, shareToAll, remove) {
				const that = this
				that.error = null;
				try {
					const invokeArgs = {
						gameId: that.gameState.id,
						playerId: that.meplayer.id,
						group: group,
						sharedWithAll: shareToAll,
						sharedWith: sharedWith ? [sharedWith]: [],
						remove: remove ? remove : false
					};
					DEBUG.log('showCards...', invokeArgs)
					await that.closedAction_PlayerHandShareCardGroupArguments(invokeArgs)
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred -show cards - ${that.error.message}`)
				}
			},

			async unblindCards() {
				const that = this
				that.error = null;
				try {
					const invokeArgs = {
						gameId: that.gameState.id,
						playerId: that.meplayer.id
					};
					DEBUG.log('unblindCards...', invokeArgs)
					await that.openAction_UnblindHandCards(invokeArgs)
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred - unblind cards - ${that.error.message}`)
				}
			},
		}
	};
</script>
<style scoped>
.transition-group {
	min-width: 60px;
	min-height: 70px;
}
.my-list-group {
	min-width: 60px;
	min-height: 70px;
	overflow: hidden;
}
.shared {
	border-color: red !important;
}
.innerContainer_s1 {
	width:98%;
}
.innerContainer_s2 {
	display: flex;
	width:98%;
}
.innerContainer_s1 {
	display:flex;
	flex-direction: row;
	width:98%;
}
.outerContainer {
	display: flex;
	flex-direction: column;
	justify-content: space-around
}
</style>