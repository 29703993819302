<template v-if="cards"> 
		<span v-if="displayType == 'stack'" class="card-content" :class="[customClass]" 
			>
			<!--slot name="trayActions"/-->
			<div class="card-box" :style="cardBoxStyleStack()">
				<slot name="addOn"></slot>
				<draggable class="my-list-group"
					style="top:0;left:0" 
					:list="cardsCopy" :group="groupOptions"  :sort="sortable" 
					@add="onDrop" :move="onDrag" :clone="cloneItem" :disabled="draggable == false && droppable == false" 
					:component-data="customComponentData">
						<playingcard v-for="(card, cardIndex) in cards" 
							:style="cardStyle()"
							:key="className + cardIndex"
							:suit="card.suit"
							:rank="card.rank" 
							:isFaceDown="isFaceDown"
							:onClickCallback="() => handleClick(cardIndex)"
							:onDblClickCallback="() => handleDblClick(cardIndex)"
							:trayHint="trayHint">
							<template v-slot:cardActions></template>
						</playingcard>				
				</draggable>
			</div>
			<slot name="title"></slot>
		</span>
		<span v-else-if="displayType == 'spread'" class="card-list" :class="[isFaceDown ? 'deal' : 'joker']" 
			:style="cardListStyle()">
			<draggable class="my-list-group card-box" :list="cardsCopy" :group="{ name: 'cards'}"  :sort="sortable" 
				:style="cardBoxStyle()"
				@add="onDrop" :move="onDrag" :clone="cloneItem" :disabled="draggable == false && droppable == false">
				<!-- <transition-group class="transition-group" type="transition" v-if="cards" :class="[cards.length == 0 ? 'empty_box' : '']"> -->
					<playingcard v-for="(card, cardIndex) in cards" 
							:style=cardStyleWithOffset(cardIndex,cards.length)
							:key="className + cardIndex"
							:suit="card.suit"
							:rank="card.rank" 
							:isFaceDown="isFaceDown"
							:onClickCallback="() => handleClick(cardIndex)"
							:onDblClickCallback="() => handleDblClick(cardIndex)"/>
				<!-- </transition-group> -->
			</draggable>		
			<slot name="title"></slot>
		</span>
</template>

<script>
	import playingcard from "@/components/game/playingcard";
	import draggable from "vuedraggable";
	import trayutils from "@/components/game/trayutils";
	import DEBUG from '@/common/DEBUG'
	import { TableStates, TrayTypes } from '@/grpcservices/Game_pb';

	export default {
		name: "basetray",

		mixins: [
			trayutils
		],
		components: {
			playingcard,
			draggable
		},

		props: {
			tray: {
				Type: Object
			},
			reverse:{
				Type: Boolean,
				default: false
			},
			customComponentData: {
				Type: Object
			},
			trayName: {
				Type: Number
			},
			groupOptions: {
				type: Object,
				default: function() {
					return { name: 'cards'}
				}
			},
			/*cards: {
				Type: Array
			},*/
			displayType: {
				type: String,
				default: 'stack'
			},
			className: {
				type: String,
			},
			onAddItem: {
				type: Function,
				required: true
			},
			checkMove: {
				type: Function,
			},
			onClickCallback: {
				type: Function
			},
			onDblClickCallback: {
				type: Function
			},
			isFaceDown : {
				type: Boolean,
				default: true,
			},
			customClass: {
				type: String,
				default: ''
			},
			draggable: {
				type: Boolean,
				default: true,
			},
			droppable: {
				type: Boolean,
				default: true,
			},
			sortable: {
				type: Boolean,
				default: false,
			},
			trayHint: {
				type: String,
			},
		},

		 computed: {
			cards() {
				if(this.reverse) 
					return this.tray?.cards?.slice().reverse();

				return this.tray?.cards;
			},
			dragOptions() {
				return {
					animation: 200,
					group: "cards",
					disabled: false,
					ghostClass: "ghost",
				};
			},
		},


        data() {
			return {
				cardsCopy: [],
                timeoutVal: -1,
                allowGrouping: false,
                allowReorder: false,
			};
		},

		mounted() {
			if (this.cards) {
				this.cardsCopy = Object.assign([], this.cards)
			} else {
				this.cardsCopy = []
			}
		},

		methods: {
			cardStyleWithOffset(index, cardCount)
			{
				var cstyle,parentWidth = 0;
				if (this.trayName == TrayTypes.COMMUNITY_TRAY || 
				    this.trayName == TrayTypes.COMMUNITY_TRAY2 ||
					this.trayName == TrayTypes.COMMUNITY_TRAY3) {
					parentWidth = $('.CommunityTrays').width();					 										
				}
				else
					parentWidth = $('.game-container').width();
				var cardWidth = 56;
				var baseStyle = "position:absolute;min-width:66px;left:";
				if (this.trayHint == "compress") {
					cardWidth = 40;
					baseStyle = "position:absolute;min-width:40px;max-width:40px;left:";
				}
				else if (this.trayHint == "compress2") {
					cardWidth = 56;
					baseStyle = "position:absolute;min-width:56px;max-width:56px;left:";
				}
				else if (this.trayHint == "compressTicTacToe") {
					cardWidth = 56;
					baseStyle = "position:absolute;min-width:56px;max-width:56px;left:";
				}
				if(parentWidth < cardWidth*cardCount)
					cstyle = baseStyle+index*100*(1/cardCount)+"%";
				else {
					var leftPos = (parentWidth - cardWidth*cardCount)/2 + index*cardWidth;
					cstyle = baseStyle+leftPos+"px";
				}
				return cstyle;
			},
			cardStyle()
			{
				return "position:absolute;top:0;left:0;width:100%;height:100%";
			},
			cardListStyle()
			{
				if (this.trayHint == "compress") {
					return "min-height:50px;height:100%;overflow:hidden;";
				}
				else if (this.trayHint == "compress2") {
					return "min-height:80px;height:100%;overflow:hidden;";
				}
				else if (this.trayHint == "compressTicTacToe") {
					return "min-height:80px;height:100%;overflow:hidden;";
				}
				return "min-height:100px;height:100%;overflow:hidden;";
			},
			cardBoxStyle()
			{
				if (this.trayHint == "compress") {
					return "min-height:50px;height:50px;width:100%;display;position:relative;max-width:40px"
				}
				else if (this.trayHint == "compress2") {
					return "min-height:80px;height:80px;width:100%;display;position:relative;max-width:56px"
				}
				else if (this.trayHint == "compressTicTacToe") {
					return "min-height:80px;height:80px;width:100%;display;position:relative;max-width:56px"
				}
				return "min-height:100px;height:100px;width:100%;display;position:relative;max-width:100%";
			},
			cardBoxStyleStack()
			{
				if (this.trayHint == "compress") {
					return "position:relative;width:45px;height:60px"
				}
				else if (this.trayHint == "compress2") {
					return "position:relative;width:56px;height:85px"
				}
				else if (this.trayHint == "compressTicTacToe") {
					return "position:relative;width:56px;height:85px"
				}
				return "position:relative;";
			},
			cloneItem() {
				DEBUG.log('Clone Item', this.trayName)
				return {}
			},
			onDrop: function(evt) {
				//this.setGlobalVar('$targetTray', this.trayName)
				
				if (this.droppable == false) {
					return false
				}
				if (this.getGlobalVar('$sourceTray') == this.trayName) {
					return false
				}
				if(this.isActivePlayer == false) {
					if (this.checkIfDealer && this.gameState.tableState == TableStates.DEAL) {
						//allow drop
					} else {
						DEBUG.log('Please wait for your turn')
						return false
					}
				}
				if (this.onAddItem) {
					this.onAddItem(evt.oldIndex)
				}
			},
			onDrag() {
				//return true
				if (this.draggable == false) {
					return false
				}
				if(this.isActivePlayer == false) {
					if (this.checkIfDealer && this.gameState.tableState == TableStates.DEAL) {
						//allow drag
					} else {
						DEBUG.log('Please wait for your turn')
						return false
					}
				}
				this.setGlobalVar('$sourceTray', this.trayName)
				if(this.checkMove) {
					this.checkMove()
				}
			},

			handleClick(cardIndex) {
				if (this.onClickCallback) {
					this.onClickCallback(cardIndex)
				}
			},

			handleDblClick(cardIndex) {
				if (this.onDblClickCallback) {
					this.onDblClickCallback(cardIndex)
				}
			}
		}
	};
</script>
<style scoped>
.empty_box {
	min-width: 60px;
	min-height: 90px;
	font-size: 12px;
	color: white;
}

.jokerDeck .empty_box:empty:before {
	content: 'Drag From Deck';
}

.discardDeck .empty_box:empty:before {
	content: 'Drag Open Card From Deck';
}

.declareDeck .empty_box:empty:before {
	content: 'To Show, Drop Card Here';
}

</style>