<template> 
	<basetray :tray="tray" :displayType="currentDisplay" :onAddItem="onAddItem" :isFaceDown="true" 
		:className="'deal-'+currentDisplay" :checkMove="checkMove" :draggable="true" :droppable="false" 
		:onClickCallback="() => handleCardClick()" :trayName="trayName" 
		:customClass="expand ? 'deal expand ': 'deal dealDeck ' + (shuffle ? 'shuffle': '')"
		:id="'dealDeck'" :customComponentData="getComponentData()"
		:trayHint="trayHint"
	>
		<template v-slot:title>
			<p class="title">Deck Cards
				<b-dropdown class="deck-dropdown" dropup right v-if="checkIfDealer">
					<template #button-content>
						<img src="../../assets/images/ellipsis.svg"/>
					</template>
					<!--b-dropdown-item v-if="allowDealCards" @click="dealInitialCards()">Deal</b-dropdown-item-->
					<b-dropdown-item v-if="gameState.tableState == TableStates.DEAL" @click="toggleExpand()">
						<span v-if="expand">Collapse</span>
						<span v-else>Expand</span>
					</b-dropdown-item>
					<b-dropdown-item @click="doShuffle">Shuffle</b-dropdown-item>
				</b-dropdown>
			</p>
		</template>
	</basetray>
	
</template>

<script>
	import basetray from "@/components/game/basetray";
	import trayutils from "@/components/game/trayutils";
	import { TrayTypes, TableStates} from '@/grpcservices/Game_pb';
	import { mapActions, mapState } from 'vuex'
	import DEBUG from '@/common/DEBUG';

	export default {
		name: "closedtray",

		components: {
			basetray
		},

		mixins: [
			trayutils
		],

		props: {
			tray: {
				Type: Object
			},
			trayName: {
				Type: Number
			},
			displayType: {
				Type: String
			},
			trayHint: {
				type: String,
			},		
		},

        data() {
			return {
				TableStates: TableStates,
				pickCardInProgress: false, 
				showActions: false,
				showTrayActions: true,
				currentDisplay: '',
				shuffle: false,
				expand: false,
				dealAnimation: false
			}
		},

		computed: {
			allowDealCards() {
				if (this.checkIfDealer && this.currentPlayerHandCardCount == 0) {
					return true
				}
				return false
			},
		},

		mounted() {
			this.currentDisplay = this.displayType
		},
		
		methods: {
			...mapActions(["closedAction_DealClosedTray", "closedAction_ShuffleClosedTray"]),

			getComponentData() {
				return {
					trayName: TrayTypes.DEAL_TRAY
				}
			},

			onAddItem: function() {
				DEBUG.log('Closed tray - ignore drop')
				return false
			},

			checkMove() {
				return true
			},
			
			toggleExpand() {
				this.expand = !this.expand
			},

			handleCardClick() {
				DEBUG.log('Deal card clicked')
				// handle deal card click based on game status

				if(this.currentPlayerHandCardCount > this.gameState.gameDef.rules.cardsPerHand) {
					this.showTableMessage('Not a valid move', true, true)
					return false
				}
				this.pickCard()
			},

			async pickCard() {
				const that = this
				if (!this.isActivePlayer) {
					this.showTableMessage('Please wait for your turn', true, true)
					return false
				}
				try {
					DEBUG.log('Deal card', this.trayName)
					that.meplayer.sourceTrayType = this.trayName
					that.meplayer.targetTrayType = TrayTypes.PLAYERHAND_TRAY
					that.meplayer.sourceCardIndex = 0

					await that.doMoveAction(that.gameState, that.meplayer)

					//move errors to provide/inject
					that.error = null
				} catch (error) {
					this.pickCardInProgress = false
					that.error = `unable to pick card from deck - ${error.message}`
					that.notifyError(error.message)
				}	
			},

			toggleTrayActions() {
				this.showTrayActions = !this.showTrayActions
			},

			async dealInitialCards() {
				const that = this
				//this.animateCards(await function() {
					try {
					that.closedAction_DealClosedTray({
						gameId: that.gameState.id,
					});
					this.error = null
					} catch (error) {
						this.error = `unable to deal initial cards - ${error.message}`
						that.notifyError(error.message)
					}
				//})
				return false
			},

			async doShuffle() {
				const that = this

				that.error = null;
				this.expand = false
				this.shuffle = true
				try {
					await that.closedAction_ShuffleClosedTray({
						gameId: that.gameState.id,
					});
					that.$parent.playAudio('shuffle')
					setTimeout(function () {
						that.shuffle = false
					}, 1500);
					
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred in shuffle- ${that.error}`)
				}
			},

		}
	};
</script>