import { render, staticRenderFns } from "./discardtray.vue?vue&type=template&id=05d9403a&v-if=(gameState.tableState%20%3D%3D%20TableStates.DEAL%20%26%26%20checkIfDealer)%20%7C%7C%0A%09%09%09%09%09%09(gameState.tableState%20%3D%3D%20TableStates.PLAY%20%26%26%20%0A%09%09%09%09%09%09%09%09%09this.gameState.discardTray.cards.length%3E0)&"
import script from "./discardtray.vue?vue&type=script&lang=js&"
export * from "./discardtray.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports