import { mapActions, mapState } from 'vuex'
import DEBUG from '@/common/DEBUG'
import Vue from 'vue'

export default {
    computed: {
        ...mapState(["gameState", "gameDef", "userinfo", "tableMessage"]),

        meid() {
            return this.userinfo.id;
        },
        ishost() {
            return this.gameState?.housePlayerId == this.userinfo?.id
        },

        meplayer() {
            return _.find(this.gameState.participants, {id: this.meid})
        },
        
        checkIfDealer() {
            return this.gameState?.dealerPlayerId == this.meid 
        },

        isActivePlayer() {
            if (this.gameState?.activePlayerId == this.meid) {
                return true
            }
            return false
        },
        currentPlayerHandCardCount() {
            var count = 0
            if (this.meplayer?.hand?.groups) {
                _.each(this.meplayer.hand.groups, function(groupObj, key) {
                    _.each(groupObj.cards, function(card){
                        count++
                    }) 
                })
            }
            
            return count;
        },
    },
    methods: {
        ...mapActions(["getGame", "closedAction_MoveCard", "updateTableMessage"]),

        getGlobalVar(name) {
            return Vue.prototype[name] ? Vue.prototype[name] : null
        },

        async reloadGameState() {
            let that = this
            try {
                const game = await that.getGame(that.gameState.id);
            }
            catch (error) {
                that.error = `unable to refresh game - ${error.message}`
                that.notifyError(error.message)
            }
        },

        setGlobalVar(name, value) {
            Vue.prototype[name] = value
        },

        showTableMessage(msg, autoHide, isError) {
            if(isError) {
                this.$parent.playAudio('error')
            }
            if(this.tableMessage != msg) {
                this.updateTableMessage({tableMessageObj: msg, autoHide:autoHide})
            }
        },

        generateUniqueId(length) {
            if (!length) {
                length = 5
            }
            var result           = [];
            var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < length; i++ ) {
              result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
           }
           return result.join('');
        },

        checkIfPlayerActive(playerId) {
            if (this.gameState?.activePlayerId == playerId) {
                return true
            }
            return false
        },
        checkIfPlayerDealer(playerId) {
            return this.gameState?.dealerPlayerId == playerId 
        },


        async doMoveAction(game, participant) {
            const that = this
            DEBUG.log('doMoveAction...', participant)

            that.error = null;
            try {		
                const invokeArgs = {
                    gameId: this.gameState.id,
                    playerId: participant.id,
                    sourceTrayType: participant.sourceTrayType,
                    targetTrayType: participant.targetTrayType,
                };
                if (participant.sourceCardId) {
                    invokeArgs.sourceCardId = participant.sourceCardId
                } else {
                    invokeArgs.sourceCardIndex = participant.sourceCardIndex
                }
                if (participant.targetGroupName) {
                    invokeArgs.targetGroupName = participant.targetGroupName
                }
                DEBUG.log('doMoveAction - Invoke args', invokeArgs)
                await that.closedAction_MoveCard(invokeArgs)
            } catch (error) {
                that.error = JSON.stringify(error)
                that.notifyError(`an error occurred do Move- ${that.error}`)
            }
        },

    }

}