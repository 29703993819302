<template v-if="(gameState.tableState == TableStates.DEAL && checkIfDealer) ||
						(gameState.tableState == TableStates.PLAY && 
									this.gameState.discardTray.cards.length>0)">
	<basetray :reverse="true" :tray="tray" :displayType="'stack'" :onAddItem="onAddItem" :isFaceDown="false"
		:className="'discard-'" :trayName="trayName" 
		:draggable="true" :droppable="true" :customClass="expand ? 'deal discardDeck expand': 'discard discardDeck'"
		:onClickCallback="() => handleCardClick()" :id="'discardDeck'" :customComponentData="getComponentData()">
		<template v-slot:title>
			<!--p class="title" v-if="allowPickOpen && tray.cards.length <= 1">Open / Discard</p-->
			<p class="title">Open / Discard
				<b-dropdown class="deck-dropdown" dropup right v-if="tray.cards.length > 1">
					<template #button-content>
						<img src="../../assets/images/ellipsis.svg" @click="toggleExpand()"/>
					</template>
						<!--b-dropdown-item @click="toggleExpand()">
							<span v-if="expand">Collapse</span>
							<span v-else>Expand</span>
						</b-dropdown-item-->
				</b-dropdown></p>
		</template>
	</basetray>
</template>
<script>
	import basetray from "@/components/game/basetray";
	import trayutils from "@/components/game/trayutils";
	import { TrayTypes, TableStates} from '@/grpcservices/Game_pb';
	import DEBUG from '@/common/DEBUG'

	export default {
		name: "discardtray",

		components: {
			basetray
		},

		mixins: [
			trayutils
		],

		props: {
			tray: {
				Type: Object
			},
			trayName: {
				Type: Number
			},
		},

		data() {
			return {
				TableStates: TableStates,
				pickCardInProgress: false, 
				expand: false
			}
		},

		computed: {
			allowPickOpen() {
				return true
				//check is hasOpen configured
				if (this.gameState.gameDef?.rules.hasDiscardTray && this.gameState.hasOpenCard && this.checkIfDealer) {
					
					if(!this.gameState?.discardTray?.cards) {
						return true
					}
					if(!this.gameState?.discardTray?.cards.length) {
						return true
					}
				}
				return false
			},
		},
		mounted() {
			
		},

		methods: {
			onAddItem: function(selectedCard) {
				const that = this

				if (that.getGlobalVar('$sourceTray') == that.trayName) {
					return false
				}
				DEBUG.log('Discard card added', selectedCard)
				// check source tray and if is dealer
				//handle declare
				if (!that.tray.cards.length && that.allowPickOpen && that.getGlobalVar('$sourceTray') == TrayTypes.DEAL_TRAY) {
					that.pickOpenCard(selectedCard)
				} else if (that.gameState.tableState == TableStates.PLAY && that.getGlobalVar('$sourceTray') == TrayTypes.PLAYERHAND_TRAY) {
					that.discardHandCard(selectedCard)
				}
			},

			getComponentData() {
				return {
					trayName: TrayTypes.DISCARD_TRAY
				}
			},

			handleCardClick() {
				DEBUG.log('Discard card clicked')
				this.pickCard()
			},	

			async pickCard() {
				const that = this

				if(!that.isActivePlayer) {
					that.showTableMessage('Please wait for your turn', true, true)
					return false
				}
				if(that.currentPlayerHandCardCount > that.gameState.gameDef.rules.cardsPerHand) {
					DEBUG.log(`You already have picked the card - actual is ${that.currentPlayerHandCardCount} while allowed ${that.gameState.gameDef.rules.cardsPerHand}`)
					return false
				}

				try {
					DEBUG.log('Deal card', that.trayName)
					that.meplayer.sourceTrayType = that.trayName
					that.meplayer.targetTrayType = TrayTypes.PLAYERHAND_TRAY
					that.meplayer.sourceCardIndex = 0

					await that.doMoveAction(that.gameState, that.meplayer)

					//move errors to provide/inject
					that.error = null
				} catch (error) {
					that.error = `unable to pick card from deck - ${error.message}`
					that.notifyError(error.message)
				}	
			},

			async pickOpenCard(selectedIndex) {
				const that = this
				/*if (!this.allowPickOpen) {
					return
				}*/
				
				try {
					that.meplayer.sourceTrayType = that.getGlobalVar('$sourceTray')
					that.meplayer.targetTrayType = that.trayName
					that.meplayer.sourceCardIndex = 0

					await that.doMoveAction(that.gameState, that.meplayer)

					//move errors to provide/inject
					that.error = null
				} catch (error) {
					that.error = `unable to pick open card - ${error.message}`
					that.notifyError(error.message)
				}	
			},

			async discardHandCard(selectedIndex) {
				const that = this
				DEBUG.log('Discard hand card')
				
				try {
					//move errors to provide/inject
					that.meplayer.sourceTrayType = that.getGlobalVar('$sourceTray')
					that.meplayer.targetTrayType = that.trayName

					if(that.getGlobalVar('$selectedCardId')) {
						that.meplayer.sourceCardId = that.getGlobalVar('$selectedCardId')
					} else {
						that.meplayer.sourceCardIndex = selectedIndex
					}
					
					await that.doMoveAction(that.gameState, that.meplayer)

					that.error = null
				} catch (error) {
					that.error = `unable to discard card - ${error.message}`
					that.notifyError(error.message)
				}	
			},

			toggleExpand() {
				this.expand = !this.expand
			}
		}
	};
</script>