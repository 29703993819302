<template>
	<div class="invitees-list">
		<template v-if="participants">
			<a class="btn" v-for="(participant, participantId) in participants" :key="participantId">
				{{getInviteeName(participant)}}</a>
		</template>
	</div>
</template>
<script>
	import { mapActions } from 'vuex'
	import gametransitions from "@/components/game/gametransitions";

	export default {
		name: "invitees",

		mixins: [
			gametransitions
		],

		components: {
			
		},

		props: {
			participants: {
				Type: Object,
				Required: true,
			},
			gameid: {
				Type: String,
				Required: true,
			}
		},

		data() {
			return {
				isprocessing: false,
				error: null,
			}
		},

		methods: {
			...mapActions(["inviteParticipant"]),
			getInviteeName(invitee){
				if(invitee.gameotp?.length > 1)
					return invitee.userDisplayName+" ("+invitee.gameotp+")";
			
				return invitee.userDisplayName;
			},
		},
	};
</script>
<style>
	.profilecontainer {
		width: 100%;
		vertical-align: middle;
	}
</style>