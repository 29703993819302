<template>
	<div class="invite">
		<h4 class="title">Invite</h4>
		<div class="invite-form">
			<div class="form-group">
				<input v-model.trim="email" type="email" name="email" placeholder="Invitee Email id" autocomplete="email"/>
			</div>
			<div class="form-group">
				<input v-model.trim="nickName" type="text" name="given-name" placeholder="Invitee Nick Name" autocomplete="given-name"/>
				<a class="btn game-button" @click="invitePlayer()">Invite</a>
			</div>
			<fa-icon v-if="isprocessing" icon="spinner" spin show />
			<b-alert v-if="error" show variant="danger">Error when inviting participants - {{error}}</b-alert>
		</div>
	</div>
</template>
<script>
	import { mapActions } from 'vuex'
	import DEBUG from '@/common/DEBUG';

	export default {
		name: "inviteparticipants",
		props: {
			id: {
				Type: String,
				Required: true,
			},
		},

		data() {
			return {
				email: '',
				nickName: '',
				message: '',
				isprocessing: false,
				error: null,
			}
		},

		methods: {
			...mapActions(["inviteParticipant"]),

			async invitePlayer() {
				const that = this
				const email = that.email.replace(' ', '');
				const nickName = that.nickName;

				// clear error
				that.error = null;

				if (!nickName || !email) {
					that.error = "email and nickname has to be specified"
					return
				}

				that.isprocessing = true;
				try {
					const id = that.id;
					console.log("Inviting participant "+id+" "+email);
					var gameState = await that.inviteParticipant({ id, email, nickName });
					await that.notifySuccess('participant invited successfully');
					that.email = that.nickName = that.error = null;
					await that.$parent.reloadGameState();
				}
				catch (error) {
					that.error = `unable to invite participant - ${error.message}`
				}
				finally {
					that.isprocessing = false;
				}
			},
		},
	};
</script>