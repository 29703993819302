<template>
	<div class="d-flex justify-content-center">
		<fa-icon v-if="isprocessing" icon="spinner" spin show />
		<b-avatar v-else-if="useravatarurl" :src="useravatarurl" :size="size" />
		<p :class="namePosition(userid)" v-else>{{ playerName }}</p>
	</div>
</template>
<script>
	import { mapActions } from 'vuex';
	import DEBUG from '@/common/DEBUG';
	import gametransitions from "@/components/game/gametransitions";

	export default {
		name: 'profilepicture',
		mixins: [
			gametransitions
		],

		props: {
			userid: {
				Type: String,
				Required: true
			},
			playerName: {
				Type: String
			},

			size: {
				Type: String,
				Default: '6em'
			},
			meid :{
				Type: String,
				Required: true				
			}
		},

		data() {
			return {
				isprocessing: true,
				useravatarurl: null,
			}
		},

		async mounted() {
			await this.loadProfilePicture()
		},

		methods: {
			...mapActions([
				"getUserAvatar"
			]),

			async loadProfilePicture() {
				const userid = this.userid;
				this.isprocessing = true;
				try {
					DEBUG.log(`loading profile picture - ${userid}`);
					this.useravatarurl = await this.getUserAvatar();
				} catch {
					DEBUG.log(`WARN unable to load find a profile picture - ${userid}`);
				}
				finally {
					this.isprocessing = false;
				}
			},
			namePosition(id) {
				DEBUG.log(`name Position id = ${id}, meid = ${this.meid}`);

				if (id == this.meid) 
					return 'nameMe';
				let videoPosition = this.videoPositionedOnUpperSide(id);
				if (videoPosition == 0)
					// video not on upper side
					return 'nameBtm';
				else if (videoPosition == 1)
					// video on upper side
					return 'nameTop';
				// not able to determine video position
				return (id == this.meid) ? 'nameMe' : 'nameTop';
			},
		}
	}
</script>