<template>
	<div class="table-outer" v-if="meplayer != null">
		<div class="lobby-container" :class="showSidebar ? 'show': 'hide'"
			 v-if="!isprocessing && gameState && gameState.participants">
			<button type="button" class="close" @click="showSidebar = false">×</button>
			<div class="heading">
				<img src="../../assets/images/watch.svg" />
				Lobby
			</div>
			<div class="waiting-content">
				<div class="Watchers">
					<h4 class="title">Watchers</h4>
					<watchers :participants="watchers" v-if="watchers && watchers.length" />
					<p class="no-data" v-else> No body watching <br /><br /><br /></p>
				</div>
				<div class="invitees">
					<h4 class="title">Invitees</h4>
				</div>
				<invitees :participants="invitees" v-if="invitees && invitees.length" />
				<p class="no-data" v-else> No more invitees</p>
			</div>
			
			<inviteparticipants ref="invites" :id="id" v-if="isHost">
			</inviteparticipants>
		</div>
		<div class="table-container">
			<span class="gameTitle" v-if="gameState && gameState.gameDef">
				{{getGameInfo}}
			</span>
			<div class="content" :class="'player-group'+ playersOnTable.length">
				<template>
					<fa-icon v-if="isprocessing" icon="spinner" />
					<template v-else-if="!isprocessing && gameState && playersOnTable">
						<gameplayer v-for="(player) in playersOnTable"
							:isHost="isHost"
							:key="player.id"
							:playerId="player.id"
							:player="player"
							:playerindex="tablePosition(player.id, player.email)"
							:tableState="gameState.tableState"
							:isPlayerFocussed="player.focussed"
							:isPlayerActive="isPlayerActive(player.id)"
							:isPlayerDealer="isPlayerDealer(player.id)"
							:hasPlayerSeenJoker="hasPlayerSeenJoker(player)"
							:isPlayerPlayingBlind="isPlayerPlayingBlind(player)"
							:isDealer="isDealer" :isWinner="isWinner"> 
							<template v-slot:video>
								<template v-if="(gameState.gameNumber == 1 && (gameState.tableState == TableStates.PICKTOSEAT || 
													(gameState.tableState == TableStates.DEAL && 
														(allCards(player) == null || allCards(player).length == 0)
													))
												) && player.seatPickTray && player.seatPickTray.cards">
									<div :class="cardsPosition(player.id)"> 
									    <zcard v-for="(card, cardIndex) in player.seatPickTray.cards"
									    :key="cardIndex" :suit="card.suit" :rank="card.rank" :isFaceDown="false" :isMe="player.id == meid"/> 
									</div>
								</template>
								<div class="playerVideo" :title="player.email">
									<profilepicture :userid="player.id" :playerName="getPlayerName(player)" :meid="meid" />
									<p class="state"  v-if="player.state != ParticipantStates.PLAY">{{getParticipantStateName(player)}}</p>
									<template v-if="gameState.videoEnabled">
										<videocontrol :videoId="getPlayerVideoId(player.id)"
													  :muted="player.id != meid">
										</videocontrol>
										<avcontrols v-if="player.id == meid && !(gameDef.rules.hideAVControls)"
													:pauseVideo="pauseVideo"
													:pauseAudio="pauseAudio" />
									</template>
								</div>
								<!-- player hand on player -->
								<div v-if="showCardsOnPlayer (player)">
								  <div :class="cardsPosition(player.id)" @click="toggleButtonsZIndex()" :style="cardsOpacity(player)">
									<draggable class="my-list-group" :list="handCards(player)" group="groupShow" 
										:move="onMove" style="width:100%;position:absolute;top:0px;justify-content:center;z-index:100;" :id="'s-' + player.id">
										<template v-for="groupObj in handCards(player)">
											<playingcard v-for="(card, cardIndex) in groupObj.cards" 
												:key="`my-list-` + groupObj.group + cardIndex"
												:suit="card.suit"
												:rank="card.rank" 
												:cardId="card.id" 
												:isFaceDown="false"
												:isMe="tablePosition(player.id, player.email)==0">
											</playingcard>
										</template>
									</draggable>
								  </div>
								  <div v-if="isPlayerPlayingBlind(player)" :class="blindsPosition(player.id)" :title="getPlayerName(player) + ' is playing blind'">
								  </div>
								</div>
							</template>
						</gameplayer>
						<div class="game-container">
							<template v-if="gameStarted && gameDef">
								<template v-if="meplayer && meplayer.state == ParticipantStates.WAITING">
									<div >
										<p class="tableActionText">
											Game is in progress, pick a buddy and watch the game while you wait for next round....
										</p>
									</div>
								</template>
								<template v-else-if="meplayer">									
									<!-- ... game started -->
									<template v-if="gameState.tableState == TableStates.PICKTOSEAT">
										<!-- ... game started in seat pick state-->
										<div style="width:100%">
											<template v-if="!isSeatChosen || !ifAllSeatChosen">
												<template v-if="isActive"> 
													<span class="tableActionText">
															Pick a card to seat <br>
													</span>
													<seatpicktrey style="height:100px;width:100%" 
														:tray="gameState.dealTray" 
														:displayType="'spread'" :trayName="TrayTypes.DEAL_TRAY" /> 
												</template>
												<span v-else class="tableActionText"> 
														Waiting for {{getActivePlayerName()}} to pick a seat 
												</span>
											</template>
										</div>
									</template>
									<template v-else-if="gameState.tableState == TableStates.DEAL || gameState.tableState == TableStates.PLAY">
										<div class="card-top-list" style="relative"> <!--"position:absolute;top:10%">-->
											<jokertray 	v-if="canShowJokerTray" 
															:tray="gameState.jokerTray" :trayName="TrayTypes.JOKER_TRAY" :title="jokerTitle"/>
											
											<closedtray 	v-if="canShowDealTray" 
															:tray="gameState.dealTray" 
															:displayType="'stack'" 
															:trayName="TrayTypes.DEAL_TRAY" 
															:trayHint="shouldCompress()"
															/>
											
											<discardtray 	v-if="canShowDiscardTray"  
															:tray="gameState.discardTray" :trayName="TrayTypes.DISCARD_TRAY" />
											
											<declaretray 	v-if="canShowDeclareTray" 
															:tray="gameState.declareTray" :trayName="TrayTypes.DECLARE_TRAY" />
											
											<busttray 		v-if="canShowBustTray" 
															:tray="gameState.bustTray" :trayName="TrayTypes.BUST_TRAY" />

											<burntray 		v-if="canShowBurnTray" 
															:tray="gameState.burnTray" 
															:trayName="TrayTypes.BURN_TRAY"
															:trayHint="shouldCompress()"
															/>

											<template v-if="canShowPot">
												<span style="text-align: center;color: white;
															position:relative;align-self:center; 
															vertical-align: middle;
															">
														<h3>POT : {{getPot}} </h3>
												</span>
											</template> 
										</div>

										<div class="CommunityTrays" v-if="canShowCommunityTray" :style="communityTraysHeight()">	
											<communitytray v-if="canShowCommunityTray"
												:tray="gameState.communityTray" 
												:trayName="TrayTypes.COMMUNITY_TRAY"
												:trayHint="shouldCompress()">
											</communitytray>

											<communitytray v-if="canShowCommunityTray2"
												:tray="gameState.communityTray2" 
												:trayName="TrayTypes.COMMUNITY_TRAY2"
												:trayHint="shouldCompress()">>
											</communitytray>

											<communitytray v-if="canShowCommunityTray3"
												:tray="gameState.communityTray3" 
												:trayName="TrayTypes.COMMUNITY_TRAY3"
												:trayHint="shouldCompress()">>
											</communitytray>
										</div>

										<!-- My cards always show on table -->
										<playerhand ref="playHand" v-if="gameState.gameDef.rules.showHandOnTable && meplayer.hand.props.canShow && handCardCount && !hasPlayerDropped(meplayer)" 
												:cards="meplayer.hand" :handCardCount="handCardCount" :trayName="TrayTypes.PLAYERHAND_TRAY" 
												:isPlayingBlind="isPlayerPlayingBlind(meplayer)"
												style="position:relative;height:100px;"/> 	

										<tableactions :player="meplayer" :isDealerOrHost="isDealer || isHost" 
												:tableState="gameState.tableState" :isActive="isActive" />
									
									</template>							
									<template v-else-if="gameState.tableState == TableStates.SHOW">
										<div class="card-top-list"  style="position:relative;top:10px">
											<jokertray v-if="canShowJokerTray"  
														:tray="gameState.jokerTray" :trayName="TrayTypes.JOKER_TRAY" :title="jokerTitle"/>
											<busttray v-if="canShowBustTray" 
														:tray="gameState.bustTray" :trayName="TrayTypes.BUST_TRAY" />
											<declaretray v-if="canShowDeclareTray"
															:tray="gameState.declareTray" :trayName="TrayTypes.DECLARE_TRAY" />	
											<burntray 		v-if="canShowBurnTray" 
														:tray="gameState.burnTray" :trayName="TrayTypes.BURN_TRAY" />			
											<template v-if="canShowPot">
												<span style="text-align: center;color: white;
															position:relative;align-self:center; 
															vertical-align: middle;
															">
														<h3>POT : {{gameState.pot.netAmount}} </h3>
												</span>
											</template> 
										</div>
										<communitytray v-if="canShowCommunityTray"
														:tray="gameState.communityTray" :trayName="TrayTypes.COMMUNITY_TRAY">
										</communitytray>
										<template v-if="gameState.gameDef.rules.showHandOnTable">
											<playerhand v-if="playerWithShow && gameState.gameDef.gameType == GameType.RUMMY" :cards="playerWithShow.hand" style="position:relative;"
																:handCardCount="handCardCount" :trayName="TrayTypes.PLAYERHAND_TRAY" :disabled="playerWithShow.id != meid"/>
											<playerhand v-else :handCardCount="handCardCount" :cards="meplayer.hand" :trayName="TrayTypes.PLAYERHAND_TRAY" style="position:relative;"/>
										</template>
										<tableactions :winner="playerWithShow" :isDealerOrHost="isDealer || isHost" 
													:player="meplayer" :isActive="isActive" 
													:tableState="gameState.tableState" style="position:relative;"/>										
									</template>
									<template v-if="gameState.tableState == TableStates.DECLARE">
										<template v-if="meplayer.hand.state != ParticipantHandStates.DECLARED || gameState.gameDef.gameType == GameType.POKER">
											<div v-if="(winnerPlayer==null || winnerPlayer.id != meid) || gameState.gameDef.gameType == GameType.POKER" class="card-top-list"  style="position:relative;top:10px">
												<jokertray v-if="canShowJokerTray" 
															:tray="gameState.jokerTray" :trayName="TrayTypes.JOKER_TRAY" :title="jokerTitle"/>
												<busttray v-if="canShowBustTray" 
															:tray="gameState.bustTray" :trayName="TrayTypes.BUST_TRAY" />
												<declaretray v-if="canShowDeclareTray"  
															:tray="gameState.declareTray" :trayName="TrayTypes.DECLARE_TRAY" />														
												<burntray 	v-if="canShowBurnTray" 
															:tray="gameState.burnTray" :trayName="TrayTypes.BURN_TRAY" />
												<template v-if="canShowPot">
													<span style="text-align: center;color: white;
																position:relative;align-self:center; 
																vertical-align: middle;
																">
															<h3>POT : {{gameState.pot.netAmount}} </h3>
													</span>
												</template> 
											</div>
											<communitytray v-if="canShowCommunityTray"
															:tray="gameState.communityTray" :trayName="TrayTypes.COMMUNITY_TRAY">
											</communitytray>
											<template v-if="(winnerPlayer==null || winnerPlayer.id != meid) && gameState.gameDef.rules.showHandOnTable &&
											meplayer.hand.props.canShow">
												<playerhand  :cards="meplayer.hand" 
																	:handCardCount="handCardCount" :trayName="TrayTypes.PLAYERHAND_TRAY" style="position:relative"/>
											</template>	
										</template>
										<tableactions :winner="winnerPlayer" 
														:isDealerOrHost="isDealer || isHost" :player="meplayer" :isActive="isActive" 
														:tableState="gameState.tableState" style="position:relative"/>
										<span v-if="myHandState >= ParticipantHandStates.DECLARED" class="dInlineCtr m2">
											<b-btn class="gold-button mr-4" @click="showScoreBoard()">SCOREBOARD</b-btn>
										</span>
									</template>					
									<template v-if="gameState.tableState == TableStates.ROUNDCOMPLETE">
										<div class="card-top-list" style="relative">
											<jokertray 	v-if="canShowJokerTray" 
															:tray="gameState.jokerTray" :trayName="TrayTypes.JOKER_TRAY" :title="jokerTitle"/>
											
											<closedtray 	v-if="canShowDealTray" 
															:tray="gameState.dealTray" 
															:displayType="'stack'" 
															:trayName="TrayTypes.DEAL_TRAY" 
															:trayHint="shouldCompress()"
															/>
											
											<discardtray 	v-if="canShowDiscardTray"  
															:tray="gameState.discardTray" :trayName="TrayTypes.DISCARD_TRAY" />
											
											<declaretray 	v-if="canShowDeclareTray" 
															:tray="gameState.declareTray" :trayName="TrayTypes.DECLARE_TRAY" />
											
											<busttray 		v-if="canShowBustTray" 
															:tray="gameState.bustTray" :trayName="TrayTypes.BUST_TRAY" />

											<burntray 		v-if="canShowBurnTray" 
															:tray="gameState.burnTray" 
															:trayName="TrayTypes.BURN_TRAY"
															:trayHint="shouldCompress()"
															/>

											<template v-if="canShowPot">
												<span style="text-align: center;color: white;
															position:relative;align-self:center; 
															vertical-align: middle;
															">
														<h3>POT : {{gameState.pot.netAmount}} </h3>
												</span>  
											</template> 
										</div>
										<div class="CommunityTrays" v-if="canShowCommunityTray"  :style="communityTraysHeight()">	
											<communitytray v-if="canShowCommunityTray"
												:tray="gameState.communityTray" 
												:trayName="TrayTypes.COMMUNITY_TRAY"
												:trayHint="shouldCompress()">
											</communitytray>

											<communitytray v-if="canShowCommunityTray2"
												:tray="gameState.communityTray2" 
												:trayName="TrayTypes.COMMUNITY_TRAY2"
												:trayHint="shouldCompress()">>
											</communitytray>

											<communitytray v-if="canShowCommunityTray3"
												:tray="gameState.communityTray3" 
												:trayName="TrayTypes.COMMUNITY_TRAY3"
												:trayHint="shouldCompress()">>
											</communitytray>
										</div>
										<tableactions :winner="winnerPlayer" :isDealerOrHost="isDealer || isHost" :player="meplayer" :isActive="isActive" :tableState="gameState.tableState" 
										style="position:relative"/>
									</template>	
								</template>
							</template>
							<template v-else-if="gameState.state == GameStates.COMPLETED">
									<span v-if="isHost" class="tableActionText"> You have closed the game</span>
									<span v-else class="tableActionText"> This game room hosted by {{getHostPlayerName()}}  is closed.</span>
							</template>
							<template v-else>
								<div>
									<p class="tableActionText" v-if="isHost">
										You can begin the game when you are ready.
									</p>
									<p class="tableActionText"  v-else>
										Please wait for {{getHostPlayerName()}} to start the game
									</p>
								</div>
							</template>
							<template v-if="meplayer.participantActions != null && meplayer.participantActions.length>0">
								<div style="display:flex; position:relative; flex-directions:row; align-items:center;">
									<template v-for="ca in meplayer.participantActions" v-bind="selectedCardLabel">
										<button v-show="ca.id != PlayerActions.ID_SAVE && ca.id != PlayerActions.ID_SITOUT && ca.id != PlayerActions.ID_CHANGE_GAME" :key="ca.id" :id="ca.id" :title="ca.hint" ref="actionButtons"
												class="gold-btn" style="margin-bottom:0rem" 
												@click="playerAction2(meplayer,ca)" :disabled="disableActionButton(ca)"
												>{{actionButtonLabel(meplayer, ca)}}
										</button>
									</template>
									<div v-if="isBetChangeable(meplayer)" class="betInput">
									    <label class = "betInputLabel">{{getBetLabel()}} Amt:</label>
									    <input v-model="betAmount" class="betInputAmount" type="number" ref="BetAmount">
										<div class="buttons">
											<button class="betAmountButton" @click="setMinBet()">
												Min
											</button>
											<button class="betAmountButton" @click="setPotBet()" :disabled="disablePotButton()">
												Pot
											</button>
											<button class="betAmountButton" @click="setMaxBet()">
												Max
											</button>
										</div>
									</div>
									<span v-if="meplayer.hand.state == ParticipantHandStates.INPLAY" ref="rummyhandactions" style="display:none">
										<button v-bind="selectedCard" @click="doDiscard(selectedCard.id)">Discard {{selectedCardLabel}}</button>
										<button v-bind="selectedCard" @click="doShow(selectedCard.id)"> Declare {{selectedCardLabel}} </button>
									</span>
								</div>
								<template v-if="meplayer.participantActionNote!=''">
									<div><h4 style="color:white;">{{ReplaceEmailByName(meplayer.participantActionNote)}}</h4></div>
								</template>
							</template>											
						</div>
					</template>
					<template>
						<div class="bottom-btn-group">
							<div class="left-btn-group">
								<button class="bottom-btn icon-btn" title="Lobby" @click="showSidebar = !showSidebar">
									<fa-icon :icon="['fas', 'users']" class="icon">
								</button>
								<button class="bottom-btn icon-btn" title="View Score Board" @click="showScoreReport()">
									<fa-icon :icon="['fas', 'file-invoice-dollar']" class="icon">
								</button>
								<button class="bottom-btn icon-btn" title="Refresh Table" @click="reloadGameState()">
									<fa-icon icon="sync" class="icon"/>
								</button>
							</div>
							<div class="right-btn-group">
								<button class="bottom-btn icon-btn" title="Change password" @click="showChangePasswordModal()">
									<fa-icon :icon="['fas', 'key']" class="icon"/>
								</button>
								<button class="bottom-btn icon-btn" title="Buy-In" @click="showWalletModal()">
									<fa-icon icon="wallet" class="icon"/>
								</button>
								<button class="bottom-btn icon-btn" title="Sit Out" @click="sitOut(meplayer, ParticipantStates.SITTINGOUT)">
									<fa-icon :icon="['fas', 'sign-out-alt']" class="icon">	
								</button>
								<!--
									<button class="bottom-btn icon-btn" title="Help" @click="showHelp()">
										<fa-icon icon="question" class="icon"/>
									</button> 
								-->
							</div>
						</div>
						<div class="message" v-if="tableMessage">{{tableMessage}}</div>
						</template>
					<div class="infoText" v-if="gameState && gameState.gameDef">
						<span> <a href="/howto/" target="_blank">Help</a></span>
						<span v-if="gameState.tableState >= TableStates.DEAL">
							 <div v-if="isRummy">
							     {{gameState.dealTray.numberOfPacksUsed}} pack 
							 </div>
						</span>
						<div>
							{{getVariantInfo}} 
						</div>
						<template v-if="gameState.state != GameStates.COMPLETED &&
										gameState.tableState == TableStates.ROUNDCOMPLETE">
							<br/>
							<span v-if="meplayer.hand && meplayer.hand.amountWon > 0">Won: {{meplayer.hand.amountWon}}</span>
							<span v-else>Lost: {{myGameBet}}</span>
						</template>
					</div>
					<div v-if="gameState.sidePots != null && gameState.sidePots.length>0" class="sidePots">
						<template v-for="(sp, index) in gameState.sidePots">
							<p :key="'SidePot'+index" :title="sidePotTitle(sp)" style="margin-bottom:0.5rem">
								{{sidePotString(sp, index)}}
							</p>
						</template>
					</div>
					<!--<div class="sidePots">
						<template v-for="(n, index) in 3">
							<p :key="'SidePot'+index" :title="particpants+n" style="margin-bottom:0.5rem">
								{{n+index}}
							</p>
						</template>
					</div>
					-->
				</template>
			</div>
		</div>
		<GameSwitcher ref="gameSwitcher"/>
		<b-modal class="modal-dialog modal-sm fade" 
				style="width:35rem !important;" hide-footer ref="walletModal">
			<template #modal-title>
      			Buy-In
    		</template>
			<div class="modal-content" style="padding:0 !important">
				<div style="text-align:center;width:100%;font-size:large" >
					<p>
						Table Currency: {{gameState.currency}}
					<p>
						Your table balance is {{myGameWalletBalance}}</p>
					<p v-if="maxBuyIn<=0">	
						and you are above the max buy-in limit for this table</p>
					<p v-else-if="isPoker&&inHand">
						<b>Please wait until you finish your current hand before buying in more</b></p>
					<p v-else>
						You can buy-in up to {{maxBuyIn}} more {{gameState.currency}}{{walletBalanceDisplay}}
					</p>
					<p v-if="myWalletBalance.walletBalance<=0 || myWalletBalance.walletBalance<minBuyIn" style="font-weight:bold">
						Please add to your wallet balance before buying in						
					</p>
				</div>
				<template v-if="myWalletBalance.walletBalance>0">
					<div class="modal-body" style="padding:1rem !important" v-show="maxBuyIn>0">		
							<div style="color:white;width:100%;text-align:center;font-size:medium">Buy-in</div>
							<div style="display:flex; flex-direction:row; justify-content:center;; width:100%">
								<input v-model="buyInToAdd" type="number" ref="buyInText" 
								style="text-align:center; font-size:large; font-weight:bold; width:20%;">
							</div>
							<div style="width:100%;">
								<input type="range" style="width:100%;" id="buyIn"
									v-model.trim="buyInToAdd" placeholder="" 
									name="score" :min="minBuyIn" :value="minBuyIn" :max="maxBuyInPossible" 
									slot="10"/>
								<span style="position:relative;float:left;top:-10px;left:0px">{{minBuyIn}}</span>
								<span style="position:relative;float:right;top:-10px;right:0px">{{maxBuyInPossible}}</span>
							</div>
					</div>
				</template>
				<div class="modal-footer" style="display:block">
						<span v-if="gameState.currency != 'Points'" style="float:left;max-height:35px;">
							<label ref="topupAmountLabel" for="topupAmount">Add to Wallet Balance of {{myWalletBalance.walletBalance}} {{gameState.currency}} : </label>
							<input type="number" v-model.trim="topupAmount" style="marging-left:20px;top-margin:5px;width:100px;" maxlength="4"
										oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
										placeholder="100" id="topupAmount" name="topupAmount"/>
							<b-spinner ref="topup_spinner" style="display:none;margin-left:10px;"></b-spinner>
							<b-btn class="btn" ref="topup_btn" @click="topupCurrency" style="margin-left:10px;" title="Add money to wallet">								
								<fa-icon :icon="['fas', 'hand-holding-usd']" class="icon">
							</b-btn>
						</span>
						<span style="float:right">
							<b-btn class="btn btn-primary" @click="buyInCurrency" :disabled="disableBuyInOK" :title="buyInOkTitle" 
								   data-dismiss="modal" style="background-color:green;float:right;margin-right:5px;">Buy-In {{buyInToAdd}}</b-btn> 
							<b-btn class="btn btn-secondary" @click="hideWalletModal();" style="background-color:red;float:right;margin-right:5px;">Cancel</b-btn>
						</span>
				</div>
			</div>
		</b-modal>

		<template> 
			<b-modal class="modal-dialog fade" hide-footer ref="teenPattiVariantModal">
				<template #modal-title>
					Choose Teen Patti Variation
				</template>
				<form>
				<b-row>
					<b-col cols="3" column-width="25%" width="25%" max-width="100px">
					<b-form-group label="Variation" label-for="variant">
					<b-form-select v-model="variant">
					<option :value=0>Regular</option>
					<option :value=1>Discard One</option>
					<option :value=2>Discard Two</option>
					<option :value=3>Imagine One</option>
					<option :value=4>Low Cards Joker</option>
					<option :value=5>High Cards Joker</option>
					<option :value=6>Pairs Joker</option>
					<option :value=7>Kissing Joker</option>
					<option :value=8>Missing Joker</option>
					<option :value=9>Kissing and Missing Joker</option>
					<option :value=10>Other</option>
					</b-form-select>
					</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col cols="4">
					<b-form-group label="High or Low" label-for="highOrLow">
					<b-form-select v-model="highOrLow">
					<option :value="HiLoTypes.HIGHONLY">High</option>
					<option :value="HiLoTypes.LOWONLY">Low (Muflis)</option>
					</b-form-select>
					</b-form-group>
					</b-col>
					<b-col cols="4">
					<b-form-group label="Joker Present" label-for="jokerPresent">
					<b-form-select v-model="jokerPresent">
					<option :value="JokerTypes.NOJOKER">No Joker</option>
					<option :value="JokerTypes.OPEN">Joker Present</option>
					</b-form-select>
					</b-form-group>
					</b-col>
					<b-col cols="4">
					<b-form-group label="Bust Card Present" label-for="bustPresent">
					<b-form-select v-model="bustPresent">
					<option :value="false">No Bust Card</option>
					<option :value="true">Bust Card Present</option>
					</b-form-select>
					</b-form-group>
					</b-col>
				</b-row>
				</form>
				<div class="modal-footer">
					<b-btn class="btn btn-secondary" style="float:right; background-color:red" @click="hideTeenPattiVariantModal()">Cancel</b-btn>
					<b-btn class="btn btn-secondary" style="float:left; background-color:green" @click="changeTeenPattiVariant()">Ok</b-btn>
				</div>
			</b-modal>
        </template> 
		
		<template> 
			<b-modal class="modal-dialog fade"  size="sm" hide-footer ref="rummyVariantModal">
				<template #modal-title>
					<center>Choose Rummy Variant to play</center>
				</template>
				<form>
				<b-row>
					<b-col cols="4">
						<b-form-group label="Joker options" label-for="jokerPresent">
							<center>
								<b-form-select v-model="jokerPresent">
								<option :value="JokerTypes.OPEN">Open Present</option>
								<option :value="JokerTypes.CLOSED">Closed Joker</option>
								<option :value="JokerTypes.NOJOKER">No Joker</option>
								</b-form-select>
							</center>
						</b-form-group>
					</b-col>
				</b-row>
				</form>
				<div class="modal-footer">
					<b-btn class="btn btn-secondary" style="float:right; background-color:red" @click="hideRummyVariantModal()">Cancel</b-btn>
					<b-btn class="btn btn-secondary" style="float:left; background-color:green" @click="changeRummyVariant()">Ok</b-btn>
				</div>
			</b-modal>
        </template> 

		<template> 
			<b-modal class="modal-dialog fade" hide-footer ref="pokerVariantModal">
				<template #modal-title>
					Choose Poker Game
				</template>
				<form>
				<b-row>
					<b-col cols="3" column-width="25%" width="25%" max-width="100px">
					<b-form-group label="Variation" label-for="variant">
					<b-form-select v-model="variant">
					<option :value=0>Holdem</option>
					<option :value=1>4 Card Omaha</option>
					<option :value=2>5 Card Omaha</option>
					<option :value=3>6 Card Omaha</option>
					<!--
					<option :value=4>5 Card Stud</option>
					<option :value=5>7 Card Stud</option>
					<option :value=6>5 Card Draw</option>
					<option :value=7>Anaconda</option>
					-->
					<option :value=8>Tic Tac Toe</option>
					</b-form-select>
					</b-form-group>
					</b-col>
				</b-row>
				<b-row>
					<b-col cols="4">
					<b-form-group label="High or Low" label-for="highOrLow">
					<b-form-select v-model="highOrLow">
					<option :value="HiLoTypes.HIGHONLY">High Only</option>
					<option :value="HiLoTypes.LOWONLY">Low Only</option>
					<option :value="HiLoTypes.HIGHLOW">High Low Split</option>
					<!--
					<option :value="HiLoTypes.HIGHLOWDECLARE">High Low 'Bet Declare Bet'</option>
					-->
					</b-form-select>
					</b-form-group>
					</b-col>
					<b-col cols="4">
					<b-form-group label="Betting Type" label-for="bettingLimit">
					<b-form-select v-model="bettingLimit">
					<option :value="BettingLimits.FIXEDLIMIT">Fixed Limit</option>
					<option :value="BettingLimits.POTLIMIT">Pot Limit</option>
					<option :value="BettingLimits.NOLIMIT">No Limit</option>
					</b-form-select>
					</b-form-group>
					</b-col>
				</b-row>
				</form>
				<div class="modal-footer">
					<b-btn class="btn btn-secondary" style="float:right; background-color:red" @click="hidePokerVariantModal()">Cancel</b-btn>
					<b-btn class="btn btn-secondary" style="float:left; background-color:green" @click="changePokerVariant()">Ok</b-btn>
				</div>
			</b-modal>
        </template> 

		<scoreboard ref="scoreboardModal" :isDealer="isDealer" :isHost="isHost" :canStartGame="canStartGame" :winnerId="winnerPlayer"></scoreboard>
		<scorereport ref="scorereportModal" :isDealer="isDealer" :isHost="isHost" :canStartGame="canStartGame" :winnerId="winnerPlayer"></scorereport>

		<b-modal class="modal-dialog modal-sm fade" 
				style="width:35rem !important;" hide-footer ref="changePassword">
			<div class="modal-content" style="padding:0 !important">
				<div style="text-align:center;width:100%;font-size:large" >
					<p>
						Change password for {{userinfo.email}}
					</p>
				</div>
				<div class="modal-body" style="padding:1rem !important;align-self:center">	
					<div style="margin-bottom:20px;">
						<label for="userDisplayName">Call Name : </label> 
						<input type="text"
						class="input-box"
						v-model="userDisplayName"
						placeholder="Short Name"
						name="userDisplayName" style="margin-left:10px;"/>
					</div>
					<div>
						<label v-if="userinfo.acceptEula" for="oldpassword">Old Password : </label> 
						<input v-if="userinfo.acceptEula" type="password"
							class="input-box"
							v-model="oldpassword"
							placeholder="old password"
							name="oldpassword"  style="margin-right:10px;"/>  	
						<label for="password" style="margin-left:10px;">New Password : </label> 
						<input type="password"
							class="input-box"
							v-model="password"
							placeholder="new password"
							name="password" style="margin-left:10px;"/>  
						<label for="confirmpassword" style="margin-left:10px;">Confirm Password : </label> 
						<input type="password"
							class="input-box"
							v-model="confirmpassword"
							@keyup.enter="doChangePassword"
							placeholder="confirm new password"
							name="confirmpassword" style="margin-left:10px;"/>
					</div>
				</div>

				<div class="modal-footer" style="display:block">
						<b-btn class="btn btn-primary" @click="doChangePassword()" 
								data-dismiss="modal" style="background-color:green;float:right;margin-right:5px;">Change Password</b-btn> 
						<b-btn class="btn btn-secondary" @click="hideChangePasswordModal();" style="background-color:red;float:right;margin-right:5px;">Cancel</b-btn>
				</div>
			</div>
		</b-modal>
	</div>
	<div v-else>
		<center><fa-icon :icon="['fas', 'fa-spinner', 'fa-7x']" style="color:black;" class="icon"></center>
	</div>
</template>
<script>

	import { mapGetters, mapState, mapActions } from 'vuex';
	import DEBUG from '@/common/DEBUG';
	import { initializeGameConnection } from "@/components/hubconnection";
	import { getNumberWithOrdinal } from '@/common/utils';
	import pass from '@/components/pass';
	import profilepicture from '@/components/profilepicture';
	import GameStateHelpers from '@/common/gamestatehelpers';
	import gametransitions from "@/components/game/gametransitions";
	import gameplayer from "@/components/game/player";
	import playingcard from "@/components/game/playingcard";
	import zcard from "@/components/game/zcard";
	import jokertray from "@/components/game/jokertray";
	import busttray from "@/components/game/busttray";
	import burntray from "@/components/game/burntray";
	import closedtray from "@/components/game/closedtray";
	import seatpicktrey from "@/components/game/seatpicktray";
	import discardtray from "@/components/game/discardtray";
	import declaretray from "@/components/game/declaretray";
	import communitytray from "@/components/game/communitytray";
	import playerhand from "@/components/game/playerhand";
	import pot from "@/components/game/pot";
	import basetray from "@/components/game/basetray";
	import watchers from "@/components/game/watchers";
	import invitees from "@/components/game/invitees";
	import GameSwitcher from "@/components/home/gameswitcher";
	import inviteparticipants from '@/components/inviteparticipants';
	import tableactions from "@/components/game/tableactions";
	
	import { SuitSymbols,RankFaces } from '@/common/constants';
	
	// rtc
	import { twvideo } from "@/components/twvideo/twvideo";
	import videocontrol from "@/components/twvideo/video";
	import avcontrols from "@/components/twvideo/avcontrols";
	import scoreboard from "@/components/game/scoreboard";
	import scorereport from "@/components/game/scorereport";
	import draggable from "vuedraggable";
	import trayutils from "@/components/game/trayutils";

	import {
		TrayTypes,
		ParticipantStates,
		ParticipantHandStates,
		GameStates, 
		TableStates, 
		DealModes,
		GameType,
		PlayerActions,
		HiLoTypes,
		JokerTypes,
		BettingLimits,
		Suits,
		Ranks,
	} from '@/grpcservices/Game_pb';

	import _ from 'lodash';

	export default {
		name: "play",

		mixins: [
			gametransitions,
			twvideo,
			trayutils
		],

		components: {
			profilepicture,
			gameplayer,
			playingcard,
			zcard,
			jokertray,
			closedtray,
			seatpicktrey,
			discardtray,
			declaretray,
			basetray,
			busttray,
			burntray,
			communitytray,
			playerhand,
			pot,
			pass,
			videocontrol,
			avcontrols,
			watchers,
			invitees,
			inviteparticipants,
			scoreboard,
			scorereport,
			tableactions,
			draggable, 
			GameSwitcher,
		},


		data(Basetray) {
			return {
				TrayTypes: TrayTypes,
				TableStates: TableStates,
				GameType: GameType,
				PlayerActions: PlayerActions,
				GameStates: GameStates,
				ParticipantStates: ParticipantStates,
				ParticipantHandStates: ParticipantHandStates,
				DealModes: DealModes,
				id: null,
				meid: null,
				isprocessing: true,
				error: null,
				gameconnection: null,
				showSidebar: false,
				videoInitialized: false,
				buyInToAdd: 0,
				topupAmount:0,
				myWalletBalance: -1,				
				selectedGameType: 0,
				variant: 0,
				HiLoTypes: HiLoTypes,
				highOrLow: HiLoTypes.HIGHONLY,
				JokerTypes: JokerTypes,
				jokerPresent: JokerTypes.NOJOKER, 
				BettingLimits: BettingLimits,
				bettingLimit: BettingLimits.FIXEDLIMIT, 
				bustPresent: false,
				selectedCard:null,
				selectedCardLabel:"",
				Suits:Suits,
				Ranks:Ranks,
				email:'',
				password:'',
				confirmpassword:'',
				oldpassword:'',
				userDisplayName:'',
				betAmount: 0,
				betAmountInitialized: false,
			};
		},
		 watch:{
			selectedGameType(){
				console.log("Game type changed...");
			}
		},
		async mounted() {
			const that = this;
			const id = that.id = that.$route.params.id;

			try {
				that.isprocessing = true
				that.meid = that.userinfo.id;
				that.email = that.userinfo.email;
				that.userDisplayName = that.userinfo.userDisplayName;
				DEBUG.log('play.vue mount init - ME =%o', that.userDisplayName);

				await that.loadGameAndGameDef(id)
				DEBUG.log('PLAY - Id=%o GameDef=%o Game=%o', id, that.gameState.gameDef, that.gameState)

				if (!that.meplayer) {
					that.showTableMessage('You do not have access to this game', false, true)
					return false
				}
				that.gameconnection = await initializeGameConnection(that.token);
				that.gameconnection.on("GameStateChanged", async (gameState) => {
					const ngameState = GameStateHelpers.processGameState(gameState);
					that.gameState = ngameState;

					if (that.gameState && that.gameState.id == id && that.gameState.version <= gameState.version) {
						const actions = gameState.actions;
						const latestAction = gameState.actions[0]
						const currentAction = ngameState.actions[0]

						if (latestAction && currentAction && latestAction.participantId != that.meid &&
							currentAction.version < latestAction.version) {
							await that.moveCardAnimation(actions)
						}

						if(latestAction && latestAction.type == "gamestartround") {
							that.showSidebar = false
						}

						if (!that.gameState.dealerPlayerId || that.gameState.dealerPlayerId != gameState.dealerPlayerId) {
							// Moving from pick to deal
							let dealerPlayer = that.getPlayerNameById(gameState.dealerPlayerId)
							if (dealerPlayer) {
								DEBUG.log('Dealer ---', dealerPlayer)
								that.showTableMessage(dealerPlayer + ' is the dealer', true)
							}
						}
						if (that.gameState.gameNumber != gameState.gameNumber) {
							that.hideScoreBoard()
						}
						await that.updateGameStateFlags(gameState)
						console.log ("In gameStateChanged n video sid = ", that.gameState.videoSid, " enabled ", that.gameState.videoEnabled)
						console.log ("In gameStateChanged video sid = ", gameState.videoSid, " enabled ", gameState.videoEnabled)
						await that.tryVideoInit(gameState)
					} else {
						DEBUG.log('Received - GameStateChanged - IGNORING local state update, already have new version...', gameState)
					}

					let routeId = that.$route.params.id
					if (!routeId || routeId != id) {
						if (gameState.id == id && gameState.state === 20) {
							DEBUG.log('GAME STATE, moving to play area - %o', gameState)
							await that.$router.push({ name: 'play', params: { id } })
						}
					}
					this.betAmountInitialized = false;
					console.log("TWV - check if we need to force reconnect for now!!!! previousTableState", that.gameState.previousTableState==TableStates.DEAL,
									" tableState:",that.gameState.tableState==TableStates.PLAY,
									"MeID :",that.meid,
									"gameType : ", that.gameState.gameDef.gameType != GameType.RUMMY);
					if(that.gameState.gameDef.gameType != GameType.RUMMY &&
							that.gameState.previousTableState==TableStates.DEAL && 
							that.gameState.tableState==TableStates.PLAY && 
							//that.meid == that.gameState.dealerPlayerId &&
							that.gameState.dealRound == 0 && 
							that.gameState.gameNumber == 1
							) {
						console.log("TWV - force Reconnecting for now!!!!");
					//	location.reload();
					}
				});
				that.buyInToAdd = that.minBuyIn;

				await that.getWalletBalance();
				// try initialize video, else attempts later in callback...
				await that.tryVideoInit(that.gameState)

				if (that.isHost && that.gameState.state != GameStates.COMPLETED) {
					that.initGame()
				}

				// if (that.gameDeclared) {
				// 	// this.showScoreBoard()
				// }

				if (that.canChangeGame && that.allGameDefs.length <= 0) {
					await that.getGameDefinitions({ friendly: true })
				}
				setTimeout(() => {
        			that.$refs.BetAmount.focus(); // calling the focus after a timeout
    			}, 0);
			}
			catch (error) {
				DEBUG.error(error);
				that.error = `unable to load game - ${error.message}`
			}
			finally {
				that.isprocessing = false
			}
		},

		async beforeDestroy() {
			DEBUG.log('disconnecting from signalr')
			this.gameconnection.stop();
		},

		watch: {
			isActive(newVal, oldVal) {
				if (newVal) {
					this.playAudio('activeuser')
				}
			},
			gameInProgress(newVal) {
				if (newVal) {
					this.showSidebar = (this.gameState.tableState == TableStates.BEGIN || 
											this.gameState.tableState == TableStates.ROUNDCOMPLETE); 
				}
			},
			myScore(newVal, oldVal) {
				if (newVal >= 0 && this.gameDeclared) {
					// this.showScoreBoard()
				}
			},
		},
		computed: {
			...mapGetters([
				'isuser_admin',
				'isuser_gamecreator',
				'isuser_gamehost',
			]),
			...mapState(["userinfo", "token", "tableMessage", "gameState", "gameDef", "allGameDefs"]),
		

			getPot () {
                return this.gameState.pot.netAmount;
            },

			isRummy () {
                return this.gameState.gameDef.gameType == GameType.RUMMY;
            },

            isTeenPatti () {
                return this.gameState.gameDef.gameType == GameType.TEENPATTI;
            },

			isPoker () {
                return this.gameState.gameDef.gameType == GameType.POKER;
            },

			getGameInfo(){
				var msg = "";
				if (this.isRummy) {
					return  this.gameState.gameDef.name+" "+(this.gameState.tableState >= TableStates.DEAL?
						getNumberWithOrdinal(this.gameState.gameNumber) + " Round "+
						getNumberWithOrdinal(this.gameState.cycle) + " Cycle": "");
				}
				else { 
					msg = this.gameState.gameDef.name;
					if (this.gameState.tableState >= TableStates.DEAL) {
						msg += " " + getNumberWithOrdinal(this.gameState.gameNumber) + " Game ";
						if (this.isTeenPatti)
							msg += getNumberWithOrdinal(this.gameState.cycle) + " Betting Cycle";
					}
				}
				return msg;
			},
			getVariantInfo(){
				return this.gameState.gameDef.variationString;
			},
			canShowPot(){
				return this.gameState.gameDef.rules.hasPot && 
							this.gameState.pot;
			},
			canShowBurnTray(){ 
				return this.gameState.gameDef.rules.hasBurnTray &&
					 		this.handCardCount && 
							((this.gameState.tableState == TableStates.DEAL && this.isDealer) || 
								this.gameState.burnTray?.cards?.length > 0);
			},
			canShowBustTray(){
				return (this.gameState.gameDef.rules.hasBustTray &&
							this.gameState.bustTray && this.handCardCount && 
							((this.gameState.tableState == TableStates.DEAL && this.isDealer) || 
								this.gameState.bustTray?.cards?.length > 0));

			},
			canShowJokerTray(){
				var rules = this.gameState.gameDef.rules;
				return rules.hasJokerTray && this.gameState.jokerTray.props.canShow;
			},
			canShowDeclareTray(){
				return this.gameState.gameDef.rules.hasDeclareTray  &&
								this.gameState.declareTray?.props.canShow;
			},
			canShowDealTray(){
				return this.gameState.dealTray && this.gameState.dealTray.props.canShow;
			},
			canShowDiscardTray(){
				return this.gameState.gameDef.rules.hasDiscardTray && this.gameState.discardTray.props.canShow;
			},
			canShowCommunityTray(){
				return this.gameState.communityTray?.props.canShow;
			},
			canShowCommunityTray2(){
				return (this.gameState.communityTray2?.props.canShow);
			},
			canShowCommunityTray3(){
				return (this.gameState.communityTray3?.props.canShow);
			},

			jokerTitle(){
				console.log("Getting joker tray title ");
				if(this.gameState.jokerTray?.cards?.length > 0)
				{ 
					var joker = this.gameState.jokerTray?.cards[0]; 
					
					if(joker.suit == Suits.JOKER)
						return "2s as Joker";

					if(joker.rank == Ranks.UNKNOWN && joker.suit == Suits.HIDDEN)
						return "Form sequence to see joker";

					return "joker";
				}
			return "Joker";
			},
			availableGameTypes(){
				const uniqueGameTypes =[...new Set(this.allGameDefs.map(item => item.gameType))];
				console.log("available unique GameTypes ", uniqueGameTypes);
				return uniqueGameTypes;
			},
			meplayer() {
				var loggedInPlayer = _.find(this.gameState.participants, { id: this.meid });
				
				if(loggedInPlayer === null)
					this.notifyError(`ERRORR Getting meplayer...`);
				
				console.log("mePlayer ", loggedInPlayer);
				return loggedInPlayer;
			},
			myCycleBet: function() {
				return this.gameState.participants ? this.meplayer.hand?.cycleBet : 0;
			},
			myRoundBet: function() {
				return this.gameState.participants ? this.meplayer.hand?.roundBet : 0;
			},
			myGameBet: function() {
				return this.gameState.participants ? this.meplayer.hand?.gameBet : 0;
			},
			myGameWallet: function() {
				return this.gameState.participants ? this.meplayer.gameWallet : null;
			},
			myGameWalletBalance: function() {
				return this.meplayer?.gameWallet?.balance;
			},
			myGameWalletTotalBuyIn: function() {
				return this.meplayer?.gameWallet?.totalBuyIn;
			},
			myScore() {
				return this.gameState.participants ? this.meplayer?.hand?.roundBet : 0;
			},
			myHandState(){
				return this.gameState.participants ? this.meplayer?.hand?.state : ParticipantHandStates.UNKNOWNHAND;
			},
			isDealer() {
				return this.gameState?.dealerPlayerId == this.userinfo?.id
			},
			isActive() {
				if (this.gameState?.activePlayerId == this.userinfo?.id) {
					return true
				}
				return false
			},
			isHost() {
				return this.gameState?.housePlayerId == this.userinfo?.id
			},
            isHostid() {
                return this.gameState?.housePlayerId
            },
			hostplayer() {
				return _.find(this.gameState.participants, { id: this.gameState.housePlayerId })
			},
			maxBuyInPossible(){
				if (this.gameState.currency == 'Points')
					return this.maxBuyIn;
				else
					return Math.min(this.maxBuyIn, this.myWalletBalance.walletBalance);
			},
			minBuyIn(){
				return this.meplayer.gameWallet.mustBuyIn;
			},
			maxBuyInAllowed(){
				var minPot =this.gameState.gameDef?.rules.minPot;
				return minPot > 0 ? minPot*200 : 5000;
			},
			maxBuyIn(){
				var gameWalletBalance = this.myGameWalletBalance;				
				var additionalBuyInPossible = this.maxBuyInAllowed - gameWalletBalance;
				return  additionalBuyInPossible;
			},
			inHand(){
				if (this.gameState.tableState != TableStates.PLAY && this.gameState.tableState != TableStates.DEAL)
					return false;
				if (this.handCardCount > 0) {
					return true;
				}
				return false;
			},
			disableBuyInOK(){
				return this.maxBuyIn <= 0 || 
				       isNaN(this.buyInToAdd) || 
					   this.buyInToAdd < this.minBuyIn || 
					   (this.gameState.currency != 'Points' && this.buyInToAdd > this.myWalletBalance.walletBalance) ||
					   this.buyInToAdd <= 0 ||
					   (this.gameState.currency != 'Points' && this.minBuyIn > this.myWalletBalance.walletBalance) || 
					   (this.isPoker && this.inHand);
			},
			buyInOkTitle() {
				if (this.disableBuyInOK)
					return 'Please specify valid amount to Buy-In';
				else
					return 'Click to Buy-In';
			},
			walletBalanceDisplay () {
				// wallet balance is not of much consequence when currency is Points
				if (this.gameState.currency == "Points")
					return "";
				
				if (this.maxBuyIn > this.myWalletBalance.walletBalance)
					return ', Your wallet balance of '+ this.myWalletBalance.walletBalance + ' is less than max allowed buy-in';
				else
					return ', Your wallet balance is '+ this.myWalletBalance.walletBalance;	
			},
			handCardCount() {
				var count = 0
				if (this.meplayer?.hand?.groups) {
					_.each(this.meplayer.hand.groups, function (groupObj, key) {
						_.each(groupObj.cards, function (card) {
							count++
						})
					})
				}

				return count;
			},
		
			playerWithShow() {
				let players = this.players?.filter(p => p.hand.state == ParticipantHandStates.DECLARED)
				return players ? players[0] : null
			},

			winnerPlayer() {
				if (this.players && this.gameDeclared) {
					let winners = this.players?.filter(p => p.hand.state == ParticipantHandStates.WON)
					return winners ? winners[0] : null
				}
			},

			sortedParticipants() {
				let that = this

				let participants = this.playersOnTable
				let sortedParticipantsList = []
				let prevObjects = []
				let nextObjects = []

				let myPositionIndex = _.findIndex(participants, function (participantObj) {
					return participantObj.id == that.meid
				});
				let myPositionObj = _.find(participants, function (participantObj) {
					return participantObj.id == that.meid
				});

				if (!myPositionObj || myPositionIndex == 0) {
					return participants
				}

				prevObjects = participants.slice(0, myPositionIndex)
				nextObjects = participants.slice(myPositionIndex + 1)

				sortedParticipantsList = [myPositionObj, ...nextObjects, ...prevObjects]
				DEBUG.log('Sorted players on table', sortedParticipantsList)
				return sortedParticipantsList
			},

			isSeatChosen() {
				if (this.gameState.gameDef && this.gameState.gameDef.rules.mustSeatDeal == false) {
					return true
				}
				if (this.meplayer && 
						(this.meplayer?.seatPickTray?.cards.length || 
						this.meplayer.state==ParticipantStates.SITTINGOUT ||
						this.meplayer.state==ParticipantStates.WAITINGTOBUYIN)) {
					return true
				}
				return false
			},

			ifAllSeatChosen() {
				if (this.gameState.gameDef && this.gameState.gameDef.rules.mustSeatDeal == false) {
					return true
				}
				let chosen = true
				_.forEach(this.players, function (participantObj) {
					if (!participantObj?.seatPickTray?.cards.length && 
						participantObj.state != ParticipantStates.SITTINGOUT &&
						participantObj.state != ParticipantStates.WAITINGTOBUYIN) {
						chosen = false
					}
				});
				return chosen
			},

			gameInProgress() {
				if (this.gameState?.tableState == TableStates.PLAY) {
					return true
				}
				return false
			},
			gameStarted() {
				if (this.gameState?.state == GameStates.INPROGRESS) {
					return true
				}
				return false
			},
			gameDeclared() {
				if (this.gameState?.tableState == TableStates.DECLARE || this.gameState?.tableState == TableStates.ROUNDCOMPLETE) {
					return true
				}
				return false
			},

			isClosedJoker() {
				if (this.gameState.gameDef?.rules?.hasJokerTray && this.gameState.gameDef?.rules?.jokerType == 1) {
					return true
				}
				return false
			},
			
			canChangeGame(){
				return ((this.gameState.state == GameStates.INLOBBY || this.gameState.tableState == TableStates.DEAL ||
								this.gameState.tableState == TableStates.ROUNDCOMPLETE));	
			},
			canStartGame() {
				return ((this.gameState.state == GameStates.INLOBBY ||
								this.gameState.tableState == TableStates.ROUNDCOMPLETE));	
			},
		},
		methods: {
			...mapActions([
				"getGame",
				"startGame",
				"startGameRound",
				"getGameDefinition",
				"getGameDefinitions",
				"closedAction_DealClosedTray",
				"closedAction_DealClosedTrayAdditional",
				"closedAction_MoveNext",
				"closedAction_Fold",
				"closedAction_gameAction",
				"closedAction_ShowToAll",
				"closedAction_MoveStage",
				"closedAction_DeclareShow",
				"updateParticipantState",
				"updateTableMessage",
				"closedAction_DeclareChallenge",
				"getCurrentUserWallet",
				"openAction_BuyInCurrency",
				"openAction_TopupCurrency",
				"openAction_ChangeVariant",
				"passwordResetChallenge",
			]),

			getParticipantStateName(participant){
				switch(participant.state)
				{ 
					case ParticipantStates.SITTINGOUT: return "SITTING OUT";
					//case ParticipantStates.WAITINGTOPLAY: return "WAITING FOR NEXT GAME";
					case ParticipantStates.WAITINGTOBUYIN: return "WAITING TO BUY-IN";
				}
				return "";
			},
			cardsPosition(id) {
				let videoPosition = this.videoPositionedOnUpperSide(id);
				if (videoPosition == 0)
					// video not on upper side
					return 'card-group-top';
				else if (videoPosition == 1)
					// video on upper side
					return 'card-group-btm';
				// not able to determine video position
				return (id == this.meid) ? 'card-group-top' : 'card-group-btm';
			},
			
			blindsPosition(id) {
				let videoPosition = this.videoPositionedOnUpperSide(id);
				if (videoPosition == 0)
					// video not on upper side
					return 'blind-top';
				else if (videoPosition == 1)
					// video on upper side
					return 'blind-btm';
				// not able to determine video position
				return (id == this.meid) ? 'blind-top' : 'blind-btm';
			},

			allFaceDown (player) {
				var cardsList = this.allCards (player);
				if (cardsList == null)
					return true;
				for (var card in cardsList) { 
					if (card?.isFaceUp)
						return false;
				}
				return true;
			},

			showCardsOnPlayer (player) {
				if (!this.hasPlayerDropped(player) && !this.isPlayerNotDealt(player)) return true;
				if (this.hasSharedCards(player)) return true;
				if (!this.allFaceDown(player)) return true;
				// return true for me player
				if (this.tablePosition(player.id, player.email)==0) return true;
				return false;
			},

			cardsOpacity (player) {
				if (this.hasPlayerDropped(player) && !this.hasSharedCards(player) && 
					this.tablePosition(player.id, player.email)==0) 
					return "opacity:0.5";
				return "opacity:1.0";
			},

			communityTraysHeight () {
				if (this.gameState.gameDef.gameVariant == 8) // TicTacToe
					return "max-height: 200px"; 
				return "max-height: 120px";
			},

			async cardToDeclareOrDiscard(card){
				this.selectedCard = card;
				if(this.gameState.gameDef.gameType == GameType.RUMMY){
					if(card){
						console.log("CardToDeclareOrDiscard ", this.selectedCard);
						this.selectedCardLabel = RankFaces[card.rank] + SuitSymbols[card.suit];
						console.log("Label", this.selectedCardLabel);
						this.$refs.rummyhandactions.style.display='inline-block';
					}else{
						this.$refs.rummyhandactions.style.display='none';
					}
				}
			},
			async doDiscard(cardId){
				const that = this;
				if(!cardId){
					that.notifyError(`Do select a card to discard.`);
					return;
				}
				var allCards = that.allCards(that.meplayer);
				if(allCards?.length<=that.gameState.gameDef.rules.maxCardsCount){
					that.notifyError(`Do pick a card before you discard one.`);
					return;
				}
				console.log("discard ",cardId)
				this.$refs.playHand.discard(cardId);
			},
			async doShow(cardId) {
				const that = this;
				if(!cardId){
					that.notifyError(`Do select a card to declare your game with.`);
					return;
				}
				var allCards = that.allCards(that.meplayer);
				console.log("all player cards ", allCards);
				if(allCards?.length<=that.gameState.gameDef.rules.maxCardsCount){
					that.notifyError(`Do pick a card before you declare your game.`);
					return;
				}

				this.showConfirmDialog('Are you sure you want to declare?', async function(val) {
					if(val) {
						try {
							const invokeArgs = {
								gameId: that.gameState.id,
								playerId: that.meplayer.id,
							};
							DEBUG.log('doShow...', invokeArgs)
							await that.declareHandCard(cardId);
							that.playAudio('show');
						//	await that.closedAction_DeclareShow(invokeArgs);
						} catch (error) {
							that.error = JSON.stringify(error);
							that.undoDeclare(cardId);
							that.notifyError(`an error occurred - ${error.message}`);
						}
					} else {
						DEBUG.log('Cancelled')
						await that.reloadGameState()
					}
				})
			},
			async undoDeclare(cardId){
				const that = this
				DEBUG.log('Undo declare with card ',cardId)
				try {
					that.meplayer.sourceTrayType = TrayTypes.DECLARE_TRAY;
					that.meplayer.targetTrayType = TrayTypes.PLAYERHAND_TRAY;

					that.meplayer.sourceCardId = cardId;
					await that.doMoveAction(that.gameState, that.meplayer)
					that.error = null
				} catch (error) {
					that.error = `Unable to declare card - ${error.message}`
					that.notifyError(error.message)
				}	
			},
			async declareHandCard(cardId) {
				const that = this
				DEBUG.log('Declare with card ',cardId)
				
				try {
					that.meplayer.sourceTrayType = TrayTypes.PLAYERHAND_TRAY;
					that.meplayer.targetTrayType = TrayTypes.DECLARE_TRAY;

					that.meplayer.sourceCardId = cardId;
					await that.doMoveAction(that.gameState, that.meplayer)
					that.error = null
				} catch (error) {
					that.error = `Unable to declare card - ${error.message}`
					that.notifyError(error.message)
				}	
			},
			onMove() {
				return false;
			},
			hasRaiseAction (meplayer) {
				if (!this.isPoker)
					return false;
				let len = meplayer.participantActions.length;
				for (var i=0; i < len; i++) {
					var id = meplayer.participantActions[i].id;
					if (id == this.PlayerActions.ID_RAISE) {
						return true;
					}
				}
				return false;
			},

			getRaiseAction (meplayer) {
				if (!this.isPoker)
					return null;
				let len = meplayer.participantActions.length;
				for (var i=0; i < len; i++) {
					var id = meplayer.participantActions[i].id;
					if (id == this.PlayerActions.ID_RAISE) {
						return meplayer.participantActions[i];
					}
				}
				return null;
			},

			isBetChangeable (meplayer) {
				let ca = this.getRaiseAction (meplayer);
				if (ca == null)
					return false;
				if (!ca.label.includes ("Bet") && !ca.label.includes ("Raise"))
					return false;
				return (this.gameState.gameDef.rules.bettingLimit != BettingLimits.FIXEDLIMIT);
			},

			getMinBet (s)
			{
				// get betAmount from label
				var regex = /[\d|,|.|\+]+/g;
				var bet = s.match (regex);
				return parseFloat(bet);
			},

			actionButtonLabel(meplayer, ca) {
				if (!this.hasRaiseAction (meplayer)) {
					this.betAmountInitialized = false;
					this.betAmount = 0;
					return ca.label;
				}
				// has bet or raise
				if (ca.id == this.PlayerActions.ID_RAISE) {
					var minBet = this.getMinBet (ca.label);
					if (!this.betAmountInitialized) {
						this.betAmount = minBet;
						this.betAmountInitialized = true;
					}
					if (this.betAmount >= this.myGameWalletBalance + this.myRoundBet)
						return "All In " + this.betAmount;
					else if (ca.label.includes ("Bet"))
						return "Bet " + this.betAmount;
					else
						return "Raise To " + this.betAmount;
				}
				return ca.label;
			},
			
			disableActionButton(ca){
				if (ca.id != this.PlayerActions.ID_RAISE)
					return false;
				if (!this.isPoker || this.gameState.gameDef.rules.bettingLimit == BettingLimits.FIXEDLIMIT)
					return false;				
				return isNaN(this.betAmount) ||
					   this.betAmount < this.getMinBet(ca.label) || 
					   this.betAmount > this.myGameWalletBalance + this.myRoundBet;
			},
			setMinBet () {
				let ca = this.getRaiseAction (this.meplayer);
				if (ca != null) {
					this.betAmount = this.getMinBet(ca.label);
				}
			},
			setPotBet () {
				let potBet = this.gameState.pot.potMaxBet;
				if (potBet > this.myGameWalletBalance + this.myRoundBet)
					potBet = this.myGameWalletBalance + this.myRoundBet;
				this.betAmount = potBet;				
			},
			setMaxBet () {
				if (this.gameState.gameDef.rules.bettingLimit == BettingLimits.POTLIMIT) {
					this.setPotBet();
					return;
				}
				this.betAmount = this.myGameWalletBalance + this.myRoundBet;
			},
			getBetLabel () {
				let ca = this.getRaiseAction (this.meplayer);
				if (ca == null)
					return "Bet";
				if (ca.label.includes ("Bet"))
					return "Bet";
				else
					return "Raise To";
			},
			disablePotButton(){
				let potBet = this.gameState.pot.potMaxBet;
				if (potBet > this.myGameWalletBalance + this.myRoundBet)
					return true;
				return false;
			},
			sidePotString(sp, index) {
				let s = "";
				if (index == 0) {
					s = "Main Pot: "
				}
				else {
					s = "Side Pot" + index + ": ";
				}
				return s+sp.amount;
			},
			
			sidePotTitle(sp) {
				let s = "Participants: ";
				var len = sp.participantIds.length;
				for (var i = 0; i < len; i++) {
					if (i == 0)
						s += this.getPlayerNameById (sp.participantIds[i]);
					else
						s += ", " + this.getPlayerNameById (sp.participantIds[i]);
				}
				return s;
			},

			async getWalletBalance(){
				this.myWalletBalance = await this.getCurrentUserWallet(this.gameState.currency);
			},
			async tryVideoInit(gameState) {
				const that = this;
				try {
					if (!that.videoInitialized) {
						DEBUG.log('initializing video', gameState?.state, gameState?.videoSid);
						if (gameState && gameState.state >= GameStates.INLOBBY && gameState.videoSid) {
							// try init only once...
							that.videoInitialized = true;

							const videoEnabled = that.gameState.videoEnabled && !that.gameState.gameDef?.rules?.disableAudioVideo;
							await that.initRTC({
								meid: that.meid,
								gameId: that.id,
								textEnabled: true,
								videoEnabled,
								onFocusPlayerChanged: that.focusPlayerChanged,
								onMessageCallback: that.messageCallback,
							});
						} else {
							DEBUG.log('video initialize, game state not valid for video initialization - ', gameState?.state, gameState?.videoSid);
						}
					} else {
						DEBUG.log('video already initialized, no-op.', gameState?.state, gameState?.videoSid);
					}
				}
				catch (errorVideoInit) {
					DEBUG.error('ERROR enabling video - ', errorVideoInit)
				}
			},
			async getUserWallet() {
				const that = this;
				try {
					console.log("getUserWallet: getting current user wallet");
					return await that.getCurrentUserWallet({});
				}
				catch (error) {
					that.notifyError(`an error occurred while fetching wallet- ${error}`)
				}
			},
			async topupCurrency(){
			const that = this;
				try {
					var amountToTopup = parseInt(that.topupAmount);
					if(amountToTopup<=0) 
					{
						that.notifyError('Invalid wallet updated attempt with ',amountToTopup, " amount");
						that.playAudio('error');
						return;
					}
					
					that.$refs.topup_btn.style.display="none";
					that.$refs.topup_spinner.style.display="inline-flex";

					const invokeArgs = {
						gameId: that.gameState.id,
						playerId: that.meid,
						amount: amountToTopup 
					};
					DEBUG.log('topUp Wallet', invokeArgs);
					await that.openAction_TopupCurrency(invokeArgs);
					that.myWalletBalance = await that.getUserWallet();
					that.$refs.topup_spinner.style.display="none";
					that.$refs.topup_btn.style.display="inline-flex";
					that.notifySuccess('wallet updated to '+that.myWalletBalance.walletBalance);
				}
				catch (error) {
					that.$refs.topup_spinner.style.display="none";
					that.notifyError(`an error occurred while adding to wallet- ${error}`)
				}
			},
			async buyInCurrency() {
				const that = this;
				try {
					if (isNaN(that.buyInToAdd)) {
						this.notifyError("Invalid Buy in specified");
						return;
					}

					var buyInValue = parseInt(that.buyInToAdd);

					if((buyInValue+that.myGameWallet?.balance) > that.maxBuyInAllowed){
						this.notifyError("You can't exceed max allowed buy in of "+that.maxBuyInAllowed);
						return;
					}

					if(buyInValue < that.minBuyIn){
						this.notifyError("Min Buy in is "+that.minBuyIn);
						return;
					}

					const invokeArgs = {
						gameId: that.gameState.id,
						playerId: that.meid,
						amount: buyInValue
					};
					DEBUG.log('Buy currency', invokeArgs);
					await that.openAction_BuyInCurrency(invokeArgs);
					that.notifySuccess('BuyIn done');
					that.buyInToAdd = 0;
					that.hideWalletModal();
					await that.getWalletBalance();
				}
				catch (error) {
					that.notifyError(`an error occurred while adding to wallet- ${error}`)
				}
			},

			showTableMessage(msg, autoHide) {
				if (this.tableMessage != msg) {
					this.updateTableMessage({ tableMessageObj: msg, autoHide: autoHide })
				}
			},
			allCards(player){
				return player?.hand?.groups.flatMap((grp)=>grp.cards);
			},
			handCards(player) {
				let hasSharedCards = false
				let sharedCards = [];
				let that = this;
				if (player?.hand?.groups && this.gameState.state != GameStates.COMPLETED) {
					// check if shared cards are present
					_.each(player.hand.groups, function (groupObj) {
						if (!that.gameState.gameDef.rules.showHandOnTable || // always show on player....
								 (groupObj.sharedWithAll || _.indexOf(groupObj.sharedWith, that.meid) > -1)) {
							_.each(groupObj.cards, function (card) {
								hasSharedCards = true;
								sharedCards.push(groupObj)
								return false;
							})
						}
					})
				}
				return hasSharedCards ? sharedCards: null;
			},
			
			hasSharedCards(player) {
				let hasSharedCards = false
				let that = this;
				if (player?.hand?.groups) {
					// check if shared cards are present
					_.each(player.hand.groups, function (groupObj) {
						if ((groupObj.sharedWithAll || _.indexOf(groupObj.sharedWith, that.meid) > -1)) {
							_.each(groupObj.cards, function (card) {
								hasSharedCards = true;
								return true;
							})
						}
					})
				}
				return hasSharedCards;
			},

			isPlayerActive(playerId) {
				if (this.gameState?.activePlayerId)
					return this.gameState?.activePlayerId == playerId;
				else
					return this.gameState?.dealerPlayerId == playerId;
			},

			isPlayerDealer(playerId) {
				return this.gameState?.dealerPlayerId == playerId
			},

			hasPlayerSeenJoker(player) {
				if(this.isClosedJoker &&
					this.gameState.tableState>=TableStates.PLAY && 
					this.gameState.tableState<TableStates.ROUNDCOMPLETE && 
					player.hand?.countPureSequence > 0) {
					return true
				}
				return false
			},

			isPlayerPlayingBlind(player) {		
				return this.gameState.tableState>=TableStates.DEAL && player.isPlayingBlind;
			},

			toggleButtonsZIndex() {
				DEBUG.log ("toggle buttons to z index");
				let buttons = document.getElementsByClassName("gold-btn");
				if (buttons == null)
					return;
				buttons.forEach((button) => {
					if (button.style.zIndex == "1")
						button.style.zIndex = "110";
					else
						button.style.zIndex = "1";
				})
			},

			// sets up the board with video conference and such....
			async initGame() {
				let that = this;

				try {
					const id = that.id
					await that.startGame(id)
					that.error = null
				} catch (error) {
					that.error = `Play.VUE::initGame() Unable to init game - ${error.message}`
					that.notifyError(that.error)
				}
			},

			async startRound(gameDefId) {
				let that = this;
				try {
					const invokeArgs = {
						gameId: that.gameState.id,
						gameDefId: gameDefId ? gameDefId : that.gameState.gameDef.id
					};
					console.log("startRound : ", invokeArgs);
					
					await that.startGameRound(invokeArgs)
					if (that.gameState.tableState == TableStates.DEAL) {
						that.autoDeal()
					}
					that.error = null
				} catch (error) {
					that.error = `unable to start round - ${error.message}`
					that.notifyError(that.error)
				}
			},

			async doMoveStage(tableState) {
				const that = this
				DEBUG.log('doMoveStage...')
				this.showTableMessage(' ')

				that.error = null;
				try {
					const invokeArgs = {
						gameId: this.gameState.id,
						tableState: tableState,
					};
					await that.closedAction_MoveStage(invokeArgs);
					if (tableState == TableStates.DEAL) {
						this.autoDeal()
					}
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred while move to deal - ${error.message}`)
				}
			},

			autoDeal() {
				//this.doShuffleAnimation()
				this.dealInitialCards()
			},

			async dealInitialCards() {
				const that = this
				//this.animateCards(await function() {
				try {
					that.closedAction_DealClosedTray({
						gameId: that.gameState.id,
					});
					this.error = null
				} catch (error) {
					this.error = `unable to deal initial cards - ${error.message}`
					that.notifyError(error.message)
				}
				//})
				return false
			},

			async dealAdditionalCards(isFaceUp) {
				const that = this
				//this.animateCards(await function() {
				try {
					that.closedAction_DealClosedTrayAdditional({
						gameId: that.gameState.id,
						noOfCards: 1,
						isFaceUp: isFaceUp
					});
					this.error = null
				} catch (error) {
					this.error = `unable to deal initial cards - ${error.message}`
					that.notifyError(error.message)
				}
				//})
				return false
			},
			async doPlayNext(playerId) {
				const that = this
				that.error = null;
				this.showTableMessage(' ')

				try {
					const invokeArgs = {
						gameId: that.gameState.id,
						playerId: playerId,
					};
					
					if(that.gameState.gameDef.rules.hasPot){
						that.$refs.playPot.addToPot(null, async function(){
							console.log("Play.VUE doPlayNext callback from Pot.addToPot()")
							await that.closedAction_MoveNext(invokeArgs);
						});
					}
					else{
						await that.closedAction_MoveNext(invokeArgs);
					}
					
				} catch (error) {
					DEBUG.error(error);
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred in play next - ${error.message}`)
				}
			},
			async changeVariantEx(){		
				var gameType = this.gameState.gameDef.gameType;
				console.log(`Changing game variant...on server request... for game ${gameType}`);
				switch(gameType){
					case GameType.RUMMY:
						return this.showRummyVariantModal();
					case GameType.TEENPATTI:
						return this.showTeenPattiVariantModal();
					case GameType.POKER:
						return this.showPokerVariantModal();
				}
				return null;
			},
			async changeGameEx(){		
				console.log("Changing game...on server request...");
				this.$refs.gameSwitcher.show();
			},
			async changeGame(gameDefId){
				const that = this
				try {
					console.log("Changing game to ",gameDefId, " from ",that.gameState.gameDef.id," for game ",that.gameState.id);
					const invokeArgs = {
						gameId: that.gameState.id,
						gameDefId: gameDefId,
						playerId: that.meplayer,
						actionId: PlayerActions.ID_CHANGE_GAME,
					};
					console.log("change game action triggered with arguments: ",invokeArgs);
					await that.closedAction_gameAction(invokeArgs);
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred while changing game - ${error.message}`)
				}
			},
			async changeVariant(){
				const that = this
				try {
					console.log("Changing variation: game=", that.gameState.gameDef.gameType, 
					             "varaint=", that.variant, 
								 "hi lo type=", that.highOrLow, 
								 "joker present=", that.jokerPresent,
								 "bust card present=", that.bustPresent,
								 "betting limit=", that.bettingLimit);
					const invokeArgs = {
						gameId: that.gameState.id,
						playerId: that.meplayer.id,
						variant: that.variant,
						highOrLow: that.highOrLow,
						jokerPresent: that.jokerPresent,
						bustPresent: that.bustPresent,
						bettingLimit: that.bettingLimit
					};
					console.log("change variant triggeed with args: ",invokeArgs);
					await that.openAction_ChangeVariant(invokeArgs);
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred while changing variation - ${error.message}`)
				}
			},
			async doPlayerAction(playerId, id){
				const that = this
				try {
						const invokeArgs = {
							gameId: that.gameState.id,
							gameDefId: that.gameState.gameDef.id,
							playerId: playerId,
							actionId: id,
							betAmount:that.betAmount,
						};
						console.log("doPlayer action triggered with arguments: ",invokeArgs);
						await that.closedAction_gameAction(invokeArgs);
					} catch (error) {
						that.error = JSON.stringify(error)
						that.notifyError(`an error occurred while calling generic player action - ${error.message}`)
					}
				this.betAmountInitialized = false;
			},
			async playerAction2(playerId, ca) 
			{
				console.log(playerId," triggered action ",ca);

				var id = ca.id;
				var prompt = ca.confirmationPrompt;
				var callStr = ca.clientCall;
				
				console.log(id," with prompt - ",prompt," & client Call - ", callStr);
				return this.playerAction(playerId, id, prompt, callStr);
			},
			async playerAction(playerId, id, prompt="", callStr="")
			{
				console.log("playeraction called with ",id," prompt - ",prompt," callStr - ",callStr);
				var ret;
				const that = this;
				if(!(callStr==="")) 
					return eval(callStr);
				

				if(prompt === "") 
					that.doPlayerAction(playerId, id)
				else
				{
					that.showConfirmDialog(prompt, async function (val) {
						if(val) that.doPlayerAction(playerId,id);
					});
				}
			},
			async showChangePasswordModal() {
				this.$refs.changePassword.show(); 
			},
			hideChangePasswordModal() {
				this.$refs.changePassword.hide();
			},			
			async doChangePassword() {
				const that = this; 
				const email = that.email;
				const password = that.password;
				const oldpassword = that.oldpassword;
				const userDisplayName = that.userDisplayName;

				const token = that.userinfo.acceptEula ? oldpassword : "";

				if (password == '') {
					alert('password is required. please fill and try again');
					return
				}
				if (that.confirmpassword == '') {
					alert('confirmpassword is required. please fill and try again');
					return
				}
				if (that.confirmpassword != password) {
					alert('password/confirmpassword does not match. please try again');
					return
				}
				try {
					var change = true;
					await that.passwordResetChallenge({email, password, change, userDisplayName, token});
					that.userinfo.acceptEula = true; // hack, user info should have been updated...
					await that.notifySuccess(`Your password has been reset`)
					that.hideChangePasswordModal();					
					that.doPlayerAction(that.meid,PlayerActions.ID_CHANGED_PASSWORD);
				}
				catch (error) {
					DEBUG.log('ERROR when resetting the password - %o', error)
					that.notifyError(`Unable to reset the password - ${error.message}`)
				}
			},
			async doFold(playerId) {
				const that = this
				that.error = null;
				//TO-DO - Move to common modal
				this.showConfirmDialog('Are you sure you want to fold?', async function (val) {
					if (val) {
						try {
							const invokeArgs = {
								gameId: that.gameState.id,
								playerId: playerId,
							};
							DEBUG.log('doFold...', invokeArgs)
							await that.closedAction_Fold(invokeArgs);
						} catch (error) {
							that.error = JSON.stringify(error)
							that.notifyError(`an error occurred while drop participant - ${error.message}`)
						}
					} else {
						DEBUG.log('Cancelled')
					}
				})
			},
			async doShowToAll(playerId) {
				const that = this
				that.error = null;

				try {
					const invokeArgs = {
						gameId: that.gameState.id,
						playerId: playerId,
					};
					DEBUG.log('doFold...', invokeArgs)
					await that.closedAction_ShowToAll(invokeArgs);
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred while doing a show - ${error.message}`)
				}
			},
			async declareWinner(playerId) {
				let that = this
				DEBUG.log('doDeclareChallenge...accept', playerId)

				that.error = null;
				try {
					const invokeArgs = {
						gameId: that.gameState.id,
						playerId: playerId,
                        score: 0,
						hasWon: true,
						closeGame: false,
					};
					await that.closedAction_DeclareChallenge(invokeArgs);
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred in declare winner - ${error.message}`)
				}	
			},
			async loadGameAndGameDef(id) {
				const that = this
				that.isprocessing = true;
				try {
					const game = await that.getGame(id);
					if (!game) {
						throw `game could not be found - ${id}`
					}

					that.error = null;
				}
				catch (error) {
					DEBUG.error(error);
					that.error = `unable to load game - ${error.message}`
					that.notifyError(error.message)
				}
				finally {
					that.isprocessing = false;
				}
			},
			tablePosition(playerId, email) {
				let myPositionIndex = _.findIndex(this.sortedParticipants, function (participantObj) {
					return participantObj.id == playerId
				});
				//DEBUG.log('Table Position', email, myPositionIndex)
				return myPositionIndex
			},
			async showHelp(){
				window.open("/howto/", "_blank");
			},
			async reloadGameState() {
				let that = this
				try {
					let game = await that.getGame(that.gameState.id);
					await that.updateGameStateFlags(game)
					that.$forceUpdate();
				}
				catch (error) {
					that.error = `unable to refresh game - ${error.message}`
					that.notifyError(error.message)
				}
			},
			async sitIn(participant) {
				// not used as I Am Back is not a generic action button
				const that = this
				that.error = null;

				await this.updatePlayerState(participant, ParticipantStates.PLAYING)
			},
			async showWalletModal() {
				await this.getWalletBalance();
				this.buyInToAdd = this.minBuyIn;
				this.$refs.walletModal.show();
				setTimeout(() => {
        			this.$refs.buyInText.focus(); // calling the focus after a timeout
    			}, 0);
			},
			hideWalletModal() {
				this.$refs.walletModal.hide();
			},			
			async showTeenPattiVariantModal() {
				console.log("Showing teenpatti variant modal");	
				this.variant = 0;
				this.highOrLow = HiLoTypes.HIGHONLY;
				this.jokerPresent = JokerTypes.NOJOKER;
				this.bustPresent = false;
				this.bettingLimit = BettingLimits.FIXEDLIMIT;
				this.$refs.teenPattiVariantModal.show(); 
			},			
			hideTeenPattiVariantModal() {
				this.$refs.teenPattiVariantModal.hide();
			},
			changeTeenPattiVariant() {
				this.changeVariant();
				this.$refs.teenPattiVariantModal.hide();
			},

			async showPokerVariantModal() {
				console.log("Showing poker variant modal");	
				this.variant = this.gameState?.gameDef?.gameVariant;
				this.highOrLow = this.gameState?.gameDef?.rules.hiLoType;
				this.jokerPresent = JokerTypes.NOJOKER;
				this.bustPresent = false;
				this.bettingLimit = this.gameState?.gameDef?.rules.bettingLimit;
				this.$refs.pokerVariantModal.show(); 
			},			
			hidePokerVariantModal() {
				this.$refs.pokerVariantModal.hide();
			},
			changePokerVariant() {
				this.changeVariant();
				this.$refs.pokerVariantModal.hide();
			},

			async showRummyVariantModal() {
				console.log("Showing rummy variant modal");	
				this.variant = this.gameState?.gameDef?.gameVariant;
				this.jokerPresent = JokerTypes.CLOSED;
				this.$refs.rummyVariantModal.show(); 
			},			
			hideRummyVariantModal() {
				this.$refs.rummyVariantModal.hide();
			},
			changeRummyVariant() {
				this.changeVariant();
				this.$refs.rummyVariantModal.hide();
			},

			showScoreBoard() {
				this.$refs.scoreboardModal.show();
			},
			hideScoreBoard() {
				this.$refs.scoreboardModal.hide();
			},
			showScoreReport() {
				this.$refs.scorereportModal.show();
			},
			hideScoreReport() {
				this.$refs.scorereportModal.hide();
			},
			focusPlayerChanged(focusPlayerId) {
				DEBUG.log('changing focussed player id - ', focusPlayerId)

				this.players.forEach((p) => {
					p.focussed = p.id == focusPlayerId;
					DEBUG.log('player focussed state - ', p.id, p.focussed);
				})
			},
			messageCallback(message, participant) {
				DEBUG.log('GAMETABLE RECEIVED MESSAGE - ', message, participant);
			},
			shouldCompress () {
				if (this.gameState.gameDef.gameVariant == 8) // TicTacToe
					return "compressTicTacToe";
				if (this.gameState.communityTray2?.cards?.length > 0)
					return "compress";
				if (this.gameState.communityTray != null)
					return "compress2";
				return "";					
			},
			showConfirmDialog(message, cb) {
				this.$bvModal.msgBoxConfirm(message, {
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'danger',
					okTitle: 'YES',
					cancelTitle: 'NO',
					hideHeaderClose: false,
					centered: true
				})
				.then(value => {
					if (cb) {
						cb(value)
					}
				})
				.catch(err => {
					// An error occurred
				})
			}
		},
	}
</script>
<style scoped>
	.tableActionText {
		color: white;
		text-align: center;
		font-size: large;
		font-weight: 600
	}

	.infoText {
		top: 25%;
		left: 4%;
		color: white;
		position: absolute;
		font-weight: 500;
		text-align: left;
	}
	.gameTitle{
		top: 0px;
		color: white;
		position: absolute;
		font-weight: 500;
		text-align: center;
		background:black;
		border-radius:15px;
		z-index: 0;
		padding:0px;
	}

</style>
