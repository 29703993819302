import DEBUG from '@/common/DEBUG'
import { mapActions } from 'vuex'
import {
	TrayTypes,
	ParticipantStates,
	ParticipantHandStates,
	GameStates, 
	TableStates,
	GameType,
	updateParticipantState,
} from '@/grpcservices/Game_pb';

// let allowedActions = {
// 	GameType.TEENPATTI : ['show_btn', 'bet_btn', 'declare_winner']
// 	GameType.RUMMY : ['show_score', 'sort_btn', 'show_message']
// };

export default {
	data() {
	},
	
	computed: {

		// all the players on the table so includes sitting out players
		playersOnTable() {
			var sortedParticipantArray = _.sortBy(this.gameState.participants, 'position')
			return _.filter(sortedParticipantArray, (p => (p.state == ParticipantStates.PLAYING || 
				p.state == ParticipantStates.SITTINGOUT ||
				p.state == ParticipantStates.WAITINGTOPLAY ||
				p.state == ParticipantStates.WAITINGTOBUYIN)))
		},

		// players in the game
		players() {
			var sortedParticipantArray = _.sortBy(this.gameState.participants, 'position')
			return _.filter(sortedParticipantArray, { 'state': ParticipantStates.PLAYING})
		},

		watchers() {
			return this.gameState?.participants ? 
				_.filter(this.gameState.participants, { 'state': ParticipantStates.WAITING}) : []

		},

		invitees() {
			return this.gameState?.participants ? 
				_.filter(this.gameState.participants, { 'state': ParticipantStates.INVITED}) : []
		},
	},
	methods: {
		...mapActions(["updateGameState"]),

		getPlayerName(player) {
			return player && player.userDisplayName ? player.userDisplayName.split('@')[0] : ''
		},

		getPlayerById(playerId) {
			return _.find(this.gameState.participants, {id: playerId})
		},

		getPlayerByEmail(playerEmail) {
			return _.find(this.gameState.participants, {email: playerEmail})
		},

		getPlayerNameById(playerId) {
			return this.getPlayerName(this.getPlayerById(playerId))
		},

		getPlayerNameByEmail(email) {
			return this.getPlayerName(this.getPlayerByEmail(email.trim()))
		},

		ReplaceEmailByName (msg)
		{
			// for some unknown reason displayName is not available on server. hence
			// for now we are adding email. Replace email by display name if available
			let indexOfAt = msg.indexOf("@");
			if (indexOfAt < 0)
				// email not present
				return msg;
			var length = msg.length;
			// go backwards until space is found or start of string
			let indexStart = indexOfAt-1;
			while (indexStart >= 0 && msg[indexStart] != ' ')
				indexStart--;
			indexStart++;
			let indexLast = indexOfAt+1;
			while (indexLast < length && msg[indexLast] != ' ')
				indexLast++;
			let email = msg.substring(indexStart, indexLast);                
			if (email.includes("@")) {
				// has a @ assume this is an email
				let playerName = this.getPlayerNameByEmail (email);
				if (playerName?.length > 0)
					msg = msg.replace (email.trim(), playerName);
			}
			return msg;
		},
	
		hasPlayerDropped(player) {
			return player?.hand?.state == ParticipantHandStates.DROPPED;
		},

		isPlayerNotDealt(player) {
			return player?.hand?.state == ParticipantHandStates.NOTDEALT;
		},

		getActivePlayer() {
			if (this.gameState.activePlayerId) {
				return this.getPlayerById(this.gameState.activePlayerId)
			}
			return null;
		},
		getActivePlayerName() {
			if (this.gameState.activePlayerId) {
				let aPlayer = this.getPlayerById(this.gameState.activePlayerId)
				return this.getPlayerName(aPlayer)
			}
			return '';
		},
		getHostPlayerName() {
			return this.gameState.housePlayerId ? 
						this.getPlayerName(this.getPlayerById(this.gameState.housePlayerId)) : ''
		},

		getDealerPlayerName() {
			if (this.gameState.dealerPlayerId) {
				let aPlayer = this.getPlayerById(this.gameState.dealerPlayerId)
				return this.getPlayerName(aPlayer)
			}
			return ''
		},

		// returns 1 if on upper side
		// returns 0 if not on upper side
		// returns -1 if unknown
		videoPositionedOnUpperSide(id) {
			let video =  document.getElementById(id);
			let table = document.getElementsByClassName("table-outer")[0];
			if (video != null && table != null) {
				if ((video.offsetTop*100)/table.offsetHeight > 15)
					return 0;
				else
					return 1;
			}
			return -1;
		},

		cardsPosition(id) {
			let videoPosition = this.videoPositionedOnUpperSide(id);
			if (videoPosition == 0)
				// video not on upper side
				return 'card-group-top';
			else if (videoPosition == 1)
				// video on upper side
				return 'card-group-btm';
			// if we are here then we are not able to find player video position or not able to find
			// table dimensions
			return (id == this.meid) ? 'card-group-top' : 'card-group-btm';
		},
	
		sitOutAllowed (participant) {
			if (participant.state == ParticipantStates.SITTINGOUT) {
				return false;
			}
			if (!this.isPlayerNotDealt(participant) && !this.hasPlayerDropped(participant) && 
				(this.gameState.tableState == TableStates.PLAY || this.gameState.tableState == TableStates.SHOW)) {
				return false;
			}
			return true;
		},
	
		async updatePlayerState(participant, state) {
			const that = this
			that.error = null;

			try {
				const invokeArgs = {
					gameId: that.gameState.id,
					playerId: participant.id,
					participantState: state,
				};
				DEBUG.log('doUpdateParticipantState...', invokeArgs)
				await that.updateParticipantState(invokeArgs);
			} catch (error) {
				that.error = JSON.stringify(error)
				that.notifyError(`an error occurred while updating player state - ${error.message}`)
			}
		},
		
		sitOut(participant, state) {
			const that = this
			that.error = null;
			if (participant.state == ParticipantStates.SITTINGOUT) {
				this.showTableMessage('You are already sitting out', true, true)
				return false
			}
			if (!that.sitOutAllowed(participant)) {
				this.showTableMessage('You need to drop/fold your cards before sitting out', true, true)
				return false
			}
			this.updatePlayerState(participant, state)
		},
	
		async updateGameStateFlags(currentgame) {
			//DEBUG.log('Updated Game State', currentgame)
            await this.updateGameState(currentgame)
            return currentgame;
        },

		async wait(ms) {
			return new Promise(resolve => {
			  setTimeout(resolve, ms);
			});
		},

		canShow(uiElement, gameType) {
			return true;
			// return allowedActions[gameType].indexOf(uiElement)>-1;
		},

		playAudio(action) {
			let audio;
			switch (action) {
				case 'activeuser':
					audio = new Audio("/sounds/current_player.wav");
					break;
				case 'discard':
					audio = new Audio("/sounds/card_discard.wav");
					break;
				case 'draw':
					audio = new Audio("/sounds/card_draw.mp3");
					break;
				case 'drop':
					audio = new Audio("/sounds/drop_game.wav");
					break;
				case 'show':
					audio = new Audio("/sounds/game_show.wav");
					break;
				case 'declare':
					audio = new Audio("/sounds/game_show.wav");
					break;
				case 'shuffle':
					audio = new Audio("/sounds/shuffle.mp3");
					break;
				case 'deal':
					audio = new Audio("/sounds/default.wav");
					break;
				case 'error':
					audio = new Audio("/sounds/error.wav");
					break;
				default:
					audio = new Audio("/sounds/default.wav");
				  break;
			}
			if (audio) {
				audio.play();
			}
		},

		doShuffleAnimation() {
			$('.dealDeck').addClass('shuffle')
			this.playAudio('shuffle')
			setTimeout(function () {
				$('.dealDeck').removeClass('shuffle')
			}, 1500);
		},

		animateCard(sourceTrayObj, targetTrayObj) {
			var sourceTray = document.getElementById(sourceTrayObj)
			var targetTray = document.getElementById(targetTrayObj)

			if(!sourceTray || !targetTray) {
				return false
			}

			var sourceX = sourceTray.getBoundingClientRect().left 
			var sourceY = sourceTray.getBoundingClientRect().top 

			var targetX = targetTray.getBoundingClientRect().left 
			var targetY = targetTray.getBoundingClientRect().top  

			var img = $('<img />').attr({
				'id': 'dummy',
				'src': '/images/card-fold.png',
				'width': 54,
				'height': 72
			})

			$(img).css({
				'position': 'fixed',
				'z-index': 1000,
				'left': sourceX,
				'top': sourceY
			});

			$(img).prependTo('.content');
			$(img).animate({
				display: 'none',
				opacity: 0,
				left: targetX + "px",
				top: targetY + "px",
				//height: "toggle"
				}, 1000, function() {
					$("#dummy").remove()
			});
		},

		handleAnimationForAction(sourceTray, targetTray, participantId) {
			if (sourceTray == "DealTray" && targetTray == "SeatPickTray") {
				this.animateCard('dealDeck', participantId)
			}
			else if (sourceTray == "DealTray" && targetTray == "JokerTray") {
				this.playAudio('draw')
			}
			else if (sourceTray == "DealTray" && targetTray == "DiscardTray") {
				this.playAudio('draw')
			}
			else if (sourceTray == "PlayerHandTray" && targetTray == "DiscardTray") {
				this.animateCard(participantId, 'discardDeck')
				this.playAudio('discard')
			}
			else if (sourceTray == "DealTray" && targetTray == "PlayerHandTray") {
				this.animateCard('dealDeck', participantId)
				this.playAudio('draw')
			}
			else if (sourceTray == "DiscardTray" && targetTray == "PlayerHandTray") {
				this.animateCard('discardDeck', participantId)
				this.playAudio('draw')
			}
		},

		async moveCardAnimation(actions) {
			const that = this
			if (actions) {
				_.each(actions, async function(axn) {
					if (axn.type == "movecard") {
						if (axn.actionArgument1 && axn.actionArgument2) {
							that.handleAnimationForAction(axn.actionArgument1, axn.actionArgument2, axn.participantId)
							return false
						}
					} 
					else if(axn.type == "dealclosedtray") {
						// animate cards to all players
						console.log('Deal sound')
						that.playAudio('deal')
					}
					else if(axn.type == "shuffleclosedtray") {
						// animate cards to all players
						console.log('Shuffle sound')
						//that.playAudio('shuffle')
						that.doShuffleAnimation()
					}
					else if(axn.type == "foldhand") {
						// animate cards to all players
						console.log('Drop sound')
						that.playAudio('drop')
					}
					else if(axn.type == "declarescore") {
						// animate cards to all players
						console.log('Declare sound')
						that.playAudio('show')
					}
					else if(axn.type == "roundcomplete") {
						// animate cards to all players
						console.log('roundcomplete sound')
						that.playAudio('show')
					}
					else if(axn.type == "declareshow") {
						// animate cards to all players
						console.log('declareshow sound')
						that.playAudio('show')
					}
					else if(axn.type == "movestage") {
						// animate cards to all players
						console.log('movestage sound')
						that.playAudio('deal')
					}
					else if(axn.type == "declarewinner") {
						// animate cards to all players
						console.log('declarewinner sound')
						that.playAudio('show')
					}
					else if(axn.type == "declarewinner") {
						// animate cards to all players
						console.log('declarewinner sound')
						that.playAudio('show')
					}
				})
			}		

			await this.wait(1200)
			console.log('Animation Done')
		},

	}
}