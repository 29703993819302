<template>
	<basetray :tray="tray" :displayType="'stack'" :onAddItem="onAddItem" :isFaceDown="false"
		:className="'declare-'" :trayName="trayName" 
		:draggable="false" :droppable="true" :customClass="'declareDeck'" 
		:customComponentData="getComponentData()">
		<template v-slot:title>
			<p class="title">Declare</p>
		</template>
	</basetray>
</template>
<script>
	import basetray from "@/components/game/basetray";
	import trayutils from "@/components/game/trayutils";
	import { TrayTypes, TableStates} from '@/grpcservices/Game_pb';
	import DEBUG from '@/common/DEBUG'
	import { mapState, mapActions } from 'vuex';

	export default {
		name: "declaretray",

		components: {
			basetray
		},

		mixins: [
			trayutils
		],

		props: {
			tray: {
				Type: Object
			},
			trayName: {
				Type: Number
			}
		},

		data() {
			return {
				TableStates: TableStates,
				pickCardInProgress: false, 
			}
		},

		computed: {
		},

		methods: {
			...mapActions(["closedAction_DeclareShow", "closedAction_DeclareChallenge"]),

			onAddItem: function(selectedCard) {
				if (this.getGlobalVar('$sourceTray') == this.trayName) {
					return false
				}
				DEBUG.log('Declare card added ', selectedCard, " source tray card", this.getGlobalVar('$selectedCardId'))
				if (this.gameState.tableState == TableStates.PLAY && this.getGlobalVar('$sourceTray') == TrayTypes.PLAYERHAND_TRAY) {
					this.$parent.doShow(this.getGlobalVar('$selectedCardId'));
				}
				return false
			},

			getComponentData() {
				return {
					trayName: TrayTypes.DECLARE_TRAY
				}
			},
			checkMove() {
				DEBUG.log('Declare card moved')
				return false
			},
		}
	};
</script>