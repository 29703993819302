<template>
	<basetray :tray="tray" :displayType="'stack'" :onAddItem="onAddItem" :isFaceDown="false"
		:className="'bust-'" :trayName="trayName" 
		:draggable="false" :droppable="true" :customClass="'bustDeck'" 
		:customComponentData="getComponentData()">
		<template v-slot:title>
			<p class="title">Bust</p>
		</template>
	</basetray>
</template>
<script>
	import basetray from "@/components/game/basetray";
	import trayutils from "@/components/game/trayutils";
	import { TrayTypes, TableStates} from '@/grpcservices/Game_pb';
	import DEBUG from '@/common/DEBUG'
	import { mapState, mapActions } from 'vuex';

	export default {
		name: "busttray",

		components: {
			basetray
		},

		mixins: [
			trayutils
		],

		props: {
			tray: {
				Type: Object
			},
			trayName: {
				Type: Number
			},
			trayHint: {
				type: String,
			},		
		},

		data() {
			return {
				TableStates: TableStates
			}
		},

		computed: {
		},

		methods: {
			onAddItem: function(selectedIndex) {
				if (this.getGlobalVar('$sourceTray') == this.trayName) {
					return false
				}
				DEBUG.log('Bust card added', selectedIndex)
				if (this.gameState.tableState == TableStates.DEAL && this.getGlobalVar('$sourceTray') == TrayTypes.DEAL_TRAY) {
					this.bustParticipants(selectedIndex)
				}
				return false
			},

			getComponentData() {
				return {
					trayName: TrayTypes.BUST_TRAY
				}
			},
			checkMove() {
				DEBUG.log('Bust card moved')
				return false
			},

			async bustParticipants(selectedIndex) {
				const that = this

                try {
					that.meplayer.sourceTrayType = this.getGlobalVar('$sourceTray')
					that.meplayer.targetTrayType = this.trayName
					that.meplayer.sourceCardIndex = selectedIndex

					await that.doMoveAction(that.gameState, that.meplayer)

					//move errors to provide/inject
					that.error = null
				} catch (error) {
					this.pickCardInProgress = false
					that.error = `unable to pick bust card - ${error.message}`
					that.notifyError(error.message)
				}	
			},
		}
	};
</script>